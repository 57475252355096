import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthUser from "./components/Context/AuthUser";
import CustomAdsFilters from "./components/Context/AdsFilters";
import CustomRequestsFilters from "./components/Context/RequestsFilters";
import CustomAdsDepartmentFilters from "./components/Context/AdsDepartmentFilters";
import {
  AdsList,
  RequestsList,
  ContactsList,
  ConsultantsList,
  AdForm,
  RequestForm,
  ContactForm,
  ConsultantForm,
  Login,
  WebManagement,
  Catalog,
  WebHome,
  Services,
  Contact,
  MarketingCampaigns,
  MarketingCampaignsSend,
} from "./pages";

import Schedule from "./components/Schedule/Schedule";

import "./App.scss";

function App() {
  return (
    <Router>
      <div className="App">
        <AuthUser>
          <CustomAdsFilters>
            <CustomRequestsFilters>
              <CustomAdsDepartmentFilters>
                <Suspense fallback={<div>Cargando...</div>}>
                  <Routes>
                    Pages
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/anuncios" element={<AdsList />} />
                    <Route
                      exact
                      path="/peticiones"
                      element={<RequestsList />}
                    />
                    <Route exact path="/contactos" element={<ContactsList />} />
                    <Route
                      exact
                      path="/consultores"
                      element={<ConsultantsList />}
                    />
                    <Route exact path="/gestion" element={<WebManagement />} />
                    <Route exact path="/catalogos" element={<Catalog />} />
                    <Route exact path="/webhomepage" element={<WebHome />} />
                    <Route exact path="/servicios" element={<Services />} />
                    <Route exact path="/contacto" element={<Contact />} />
                    <Route
                      exact
                      path="/campaigns"
                      element={<MarketingCampaigns />}
                    />
                    <Route
                      exact
                      path="/campaigns-send"
                      element={<MarketingCampaignsSend />}
                    />
                    {/* Create Components */}
                    <Route exact path="/anuncios/crear" element={<AdForm />} />
                    <Route
                      exact
                      path="/peticiones/crear"
                      element={<RequestForm />}
                    />
                    <Route
                      exact
                      path="/contactos/crear/:name/:email/:phone"
                      element={<ContactForm />}
                    />
                    <Route
                      exact
                      path="/consultores/crear"
                      element={<ConsultantForm />}
                    />
                    {/* Sheets Components */}
                    <Route exact path="/anuncios/:id" element={<AdForm />} />
                    <Route
                      exact
                      path="/peticiones/:id"
                      element={<RequestForm />}
                    />
                    <Route
                      exact
                      path="/contactos/:id"
                      element={<ContactForm />}
                    />
                    <Route
                      exact
                      path="/consultores/:id"
                      element={<ConsultantForm />}
                    />
                    <Route exact path="/agenda" element={<Schedule />} />
                  </Routes>
                </Suspense>
              </CustomAdsDepartmentFilters>
            </CustomRequestsFilters>
          </CustomAdsFilters>
        </AuthUser>
      </div>
    </Router>
  );
}

export default App;

import "./delete.consultant.modal.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Select } from "./../../../components";
import {
  deleteConsultant,
  getAllConsultants,
} from "../../../api/consultants.api";
import PulseLoader from "react-spinners/PulseLoader";
import { FiSave } from "react-icons/fi";
import { updateManyConsultantsAds } from "../../../api/ads.api";
import { updateManyConsultantsRequets } from "../../../api/requests.api";
import useWindowSize from "../../../hooks/useWindowSize";

function DeleteConsultantModal({
  showModal,
  setShowModal,
  ads,
  requests,
  consultant,
}) {
  const size = useWindowSize();
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState("");
  const [deleteWithoutNewRef, setDeleteWithoutNewRef] = useState(false);
  const [deleteWithNewRef, setDeleteWithNewRef] = useState(false);
  const [deletingConsultant, setDeletingConsultant] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [asignNewRef, setAsignNewRef] = useState(false);
  const [isSelectedContact, setIsSelectedContact] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getAllConsultants().then((res) => {
      /* console.log('consultant:',consultant) */
      /* console.log('res',res) */
      const currentConsultantIndex = res.filter(
        (consult) => consult._id !== consultant._id
      );
      /* console.log(currentConsultantIndex) */
      setConsultants(currentConsultantIndex);
    });
  }, []);

  const onHide = () => {
    setShowModal(false);
    setDeleteWithoutNewRef(false);
    setDeleteWithNewRef(false);
    setIsDeleted(false);
  };
  const footerButton = () => {
    return (
      <button className=" buttonModalCancel" onClick={() => onHide()}>
        Cancelar
      </button>
    );
  };

  const textDescription = () => {
    if (ads.length === 0 && requests.length === 0) {
      return "no tiene anuncios ni peticiones vinculadas. El consultor se puede eliminar. ";
    }
    if (ads.length !== 0) {
      if (requests.length === 0) {
        return `tiene asociados ${ads.length} anuncios. `;
      } else {
        return `tiene asociados ${ads.length} anuncios y ${requests.length} peticiones. `;
      }
    }
    if (requests.length !== 0) {
      if (ads.length === 0) {
        return `tiene asociadas ${requests.length} peticiones. `;
      } else {
        return `tiene asociados ${ads.length} anuncios y ${ads.length} peticiones. `;
      }
    }
  };

  const buttonsFunctions = () => {
    if (ads.length === 0 && requests.length === 0) {
      return (
        <>
          <p className="consultant__modal__text">
            ¿Deséa eliminar este consultor?
          </p>
          <button
            className="buttonModal consultant__modal__button"
            onClick={() => deleteWithoutNewREference()}
          >
            Si
          </button>
          <button
            className="buttonModal consultant__modal__button"
            onClick={() => setShowModal(false)}
          >
            No
          </button>
        </>
      );
    } else {
      return (
        <>
          <p className="consultant__modal__text">
            ¿Deséa vincular estas referencias a otro consultor? Si no lo hace
            estas referencias quedarán sin asignarse a ningún consultor.
          </p>
          <button
            className="buttonModal consultant__modal__button"
            onClick={() => {
              setDeleteWithNewRef(true);
              setDeleteWithoutNewRef(false);
              setIsSelectedContact(false);
            }}
          >
            Si
          </button>
          <button
            className="buttonModal consultant__modal__button"
            onClick={() => {
              setDeleteWithoutNewRef(true);
              setDeleteWithNewRef(false);
              setIsSelectedContact(false);
            }}
          >
            No
          </button>
        </>
      );
    }
  };
  const checkIfIncludes = (origin, text) => {
    return origin
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
  };

  const assignNewReferencesToContact = async () => {
    if (selectedConsultant !== "") {
      setIsSelectedContact(false);
      setDeleteWithNewRef(true);
      setAsignNewRef(true);
      /* ads.forEach(ad => console.log('antes del cambio',ad.consultant)) */
      /* requests.forEach(req => console.log('antes del cambio',req.requestConsultant)) */
      ads.map((ad) => (ad.consultant = selectedConsultant));
      requests.map((req) => (req.requestConsultant = selectedConsultant));
      /* ads.forEach(ad => console.log('despues del cambio',ad.consultant)) */
      /* requests.forEach(req => console.log('despues del cambio',req.requestConsultant)) */
      //ahora modifico los anuncios y las peticiones llamando al endpoint
      if (ads.length > 0) await updateManyConsultantsAds(ads, consultant._id);
      if (requests.length > 0)
        await updateManyConsultantsRequets(requests, consultant._id);
      await deleteConsultant(consultant._id);
      setTimeout(() => setIsDeleted(true), 3000);
      setTimeout(() => setAsignNewRef(false), 6000);
      setTimeout(() => setSelectedConsultant(""), 6000);
      setTimeout(() => navigate("/consultores"), 6000);
      /* console.log(selectedConsultant) */
    } else {
      setIsSelectedContact(true);
    }
  };

  const deleteWithoutNewREference = () => {
    setDeleteWithoutNewRef(true);
    setDeletingConsultant(true);
    deleteConsultant(consultant._id).then(() => {
      setTimeout(() => setIsDeleted(true), 3000);
      setTimeout(() => setDeletingConsultant(false), 6000);
      setTimeout(() => navigate("/consultores"), 6000);
    });
  };

  return (
    <React.Fragment>
      {showModal ? (
        <Dialog
          header="Borrar consultor"
          visible={showModal}
          style={{ width: "50vw" }}
          footer={() => footerButton()}
          onHide={() => onHide()}
        >
          <div className="consultant__modal">
            <p className="consultant__modal__text">
              El consultor {consultant.fullName} {textDescription()}
            </p>
            {buttonsFunctions()}
          </div>
          {deleteWithoutNewRef ? (
            <>
              <div>
                <p className="consultant__modal__text">
                  Se va a borrar el consultor sin asignar uno nuevo a los
                  anuncios y peticiones que este tiene vinculados. ¿Desea
                  continuar?
                </p>
                <button
                  className="buttonModal consultant__modal__button"
                  onClick={() => deleteWithoutNewREference()}
                >
                  Si
                </button>
                <button
                  className="buttonModal consultant__modal__button"
                  onClick={() => onHide()}
                >
                  No
                </button>
              </div>
              {deletingConsultant ? (
                <>
                  {isDeleted ? (
                    <p className="consultant__modal__text text-success">
                      Contacto eliminado correctamente.
                    </p>
                  ) : (
                    <p className="consultant__modal__text">
                      Eliminando el contacto. Espere, por favor. {"  "}
                      <PulseLoader size="5" color="#2B363D" />
                    </p>
                  )}
                </>
              ) : null}
            </>
          ) : null}
          {deleteWithNewRef ? (
            <div>
              <p className="consultant__modal__text">
                Seleccione el consultor al que desea vincular estas referencias.
              </p>
              <div className="consultant__modal__container">
                <Select
                  label="Consultor"
                  list={consultants}
                  fields={{ groupBy: "", text: "fullName", value: "_id" }}
                  filter={(e) => {
                    const searchData = consultants.filter((consultant) => {
                      if (
                        checkIfIncludes(consultant.fullName, e.text) ||
                        checkIfIncludes(consultant.consultantEmail, e.text) ||
                        checkIfIncludes(
                          consultant.consultantMobileNumber,
                          e.text
                        )
                      )
                        return consultant;
                    });
                    if (searchData.length !== 0) e.updateData(searchData);
                    else e.updateData([]);
                  }}
                  fn={(e) => {
                    setSelectedConsultant(e.target.value);
                    setIsSelectedContact(false);
                  }}
                  defaultValues={selectedConsultant ? selectedConsultant : ""}
                  defaultName=""
                />
                <button
                  onClick={assignNewReferencesToContact}
                  className="buttonModalSave"
                >
                  <FiSave
                    style={
                      size > 480
                        ? { marginRight: 7 }
                        : {
                            marginRight: 7,
                            transform: "scale(125%)",
                            verticalAlign: "middle",
                          }
                    }
                  />
                  {size > 480 && "Vincular"}
                </button>
                {asignNewRef ? (
                  <>
                    {isDeleted ? (
                      <p className="consultant__modal__text text-success">
                        Contacto eliminado correctamente.
                      </p>
                    ) : (
                      <p className="consultant__modal__text">
                        Asignando los anuncios y las peticiones al nuevo
                        consultor y eliminando el contacto. <br /> Espere, por
                        favor. {"  "}
                        <PulseLoader size="5" color="#2B363D" />
                      </p>
                    )}
                  </>
                ) : null}
                {isSelectedContact ? (
                  <p className="consultant__modal__text text-danger">
                    Por favor, seleccione un contacto *.
                  </p>
                ) : null}
              </div>
            </div>
          ) : null}
        </Dialog>
      ) : null}
    </React.Fragment>
  );
}

export default DeleteConsultantModal;

import { useEffect, useState } from "react";
import LayoutWeb from "../LayoutWeb/layoutWeb";
import {
  getAllCatalogs,
  getMainImageCatalog,
  uploadSectionImageCatalog,
} from "../../api/catalogs.api";
import CatalogCard from "../../components/CatalogCard/CatalogCard";
import "./Catalog.scss";
import CatalogNewForm from "../../components/CatalogNewForm/CatalogNewForm";
import CatalogMainImage from "../../components/CatalogMainImage/CatalogMainImage";

const Catalog = () => {
  const [catalogs, setCatalogs] = useState([]);
  const [openNewCatalogForm, setOpenNewCatalogForm] = useState(false);
  const [sectionImageCatalog, setSectionImageCatalog] = useState(null);
  const [mainImageCatalog, setMainImageCatalog] = useState([]);

  async function fetchGetAllCatalogs() {
    const catalogs = await getAllCatalogs();
    const sortCatalogs = catalogs.sort(function sortCatalog(a, b) {
      return b.year - a.year;
    });
    setCatalogs(sortCatalogs);
  }

  const fetchMainImageCatalog = async () => {
    const image = await getMainImageCatalog();
    setMainImageCatalog(image);
  };

  useEffect(() => {
    fetchGetAllCatalogs();
  }, []);

  useEffect(() => {
    fetchMainImageCatalog();
  }, [mainImageCatalog.length]);

  const handleUploadSectionImgCatalog = (e) => {
    e.preventDefault();
    setSectionImageCatalog(e.target.files[0]);
  };

  const uploadSectionImage = async () => {
    const formData = new FormData();
    formData.append("mainImageCatalog", sectionImageCatalog);
    const response = await uploadSectionImageCatalog(formData);
    response && setMainImageCatalog([response.data]);
  };

  return (
    <LayoutWeb subTitle="Catálogos">
      <div className="CatalogsTitle">
        {!openNewCatalogForm && (
          <>
            <div className="Catalog-section_image-title">
              <h2>Imagen principal</h2>
            </div>
            <div className="Catalog-section_image-input">
              {mainImageCatalog?.length !== 0 ? (
                <CatalogMainImage
                  image={mainImageCatalog}
                  setMainImageCatalog={setMainImageCatalog}
                />
              ) : (
                <>
                  <input
                    type="file"
                    name="catalog"
                    id="catalog"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => handleUploadSectionImgCatalog(e)}
                  />
                  <button
                    className="subHeader__btn"
                    onClick={uploadSectionImage}
                  >
                    Subir
                  </button>
                </>
              )}
            </div>
            <div className="catalogos-Container">
              <h2>Catálogos</h2>
              <button
                type="button"
                className="subHeader__btn"
                onClick={() => setOpenNewCatalogForm(true)}
              >
                Nuevo
              </button>
            </div>
          </>
        )}
      </div>
      {openNewCatalogForm && (
        <CatalogNewForm
          setOpenNewCatalogForm={setOpenNewCatalogForm}
          catalogs={catalogs}
          setCatalogs={setCatalogs}
        />
      )}

      {!openNewCatalogForm && catalogs.length !== 0 && (
        <div className="CatalogsContent">
          {catalogs.map((catalog, i) => (
            <CatalogCard
              key={i}
              catalog={catalog}
              catalogs={catalogs}
              setCatalogs={setCatalogs}
              index={i}
            />
          ))}
        </div>
      )}
    </LayoutWeb>
  );
};

export default Catalog;

import { useEffect, useState } from "react";
import LayoutWeb from "../LayoutWeb/layoutWeb";
import ServicesCard from "../../components/ServicesCard/ServicesCard";
import { getAllWebData } from "../../api/webData.api";
import "./Services.scss";

const Services = () => {
  const [services, setServices] = useState([]);
  const [imageName, setImageName] = useState([
    "commercializationImage",
    "investMentImage",
    "assetManagementImage",
    "developmentImage",
    "interiorimsImage",
  ]);
  const [hideContent, setHideContent] = useState(false);
  const [webData, setWebData] = useState([]);

  async function fetchGetAllWebData() {
    const webData = await getAllWebData();
    const servicesArray = [
      webData[0].services.commercialization,
      webData[0].services.investment,
      webData[0].services.assetManagement,
      webData[0].services.development,
      webData[0].services.interiorims,
    ];
    setWebData(webData[0]);
    setServices(servicesArray);
  }

  useEffect(() => {
    fetchGetAllWebData();
  }, []);
  return (
    <LayoutWeb subTitle="Catálogos">
      <div className="CatalogsTitle">
        <h1>Servicios</h1>
      </div>

      {!hideContent && services.length !== 0 && (
        <div className="CatalogsContent">
          {services.map((service, i) => (
            <ServicesCard
              key={i}
              service={service}
              setServices={setServices}
              imageName={imageName[i]}
              webData={webData}
              setHideContent={setHideContent}
            />
          ))}
        </div>
      )}
    </LayoutWeb>
  );
};

export default Services;

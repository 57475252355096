import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getAdsByFilter } from "../../api/ads.api";
import AdsTable from "../../components/AdsTable/AdsTable";
import { UserContext } from "../../components/Context/AuthUser";
import Layout from "../Layout/Layout";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import { CustomAdsFilters } from "../../components/Context/AdsFilters";

const AdsList = () => {
  const [ads, setAds] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState(-1);

  const { user } = useContext(UserContext);
  const customAdsFilters = useContext(CustomAdsFilters);
  const filterSetter = customAdsFilters.setFilters;

  // Memoriza los filtros para evitar recalculos innecesarios
  const adsFilters = useMemo(
    () => customAdsFilters.adsFilters,
    [customAdsFilters.adsFilters]
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (user.length === 0) {
      navigate("/");
    }
  }, [user, navigate]);

  const paginate = useCallback(
    (pageNumber) => {
      filterSetter({
        ...adsFilters,
        page: pageNumber,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [adsFilters]
  );

  const AdsListFooter = useCallback(
    () => (
      <Pagination
        currentPage={adsFilters.page}
        totalPages={totalPages}
        paginate={paginate}
      />
    ),
    [adsFilters.page, totalPages, paginate]
  );

  useEffect(() => {
    const fetchAds = async () => {
      setLoader(true);
      const data = await getAdsByFilter({
        ...adsFilters,
        sortField,
        sortOrder: sortOrder === 1 ? "ASC" : "DESC",
      });
      if (data) {
        setAds(data.ads);
        setTotalPages(data.pageInfo.totalPages);
        setTotalElements(data.pageInfo.totalElements);
        setLoader(false);
        filterSetter({
          ...adsFilters,
          page: data.pageInfo.page,
        });
      }
    };

    fetchAds();
  }, [adsFilters.page, adsFilters.department]);

  return (
    <Layout
      subTitle="Anuncios"
      subList={totalElements}
      subLocation={() => navigate("/anuncios/crear")}
      subSetter={setAds}
      setTotalPages={setTotalPages}
      setTotalElements={setTotalElements}
      footContent={<AdsListFooter />}
    >
      {loader ? (
        <Spinner />
      ) : (
        <AdsTable
          ads={ads}
          setAds={setAds}
          currentPage={adsFilters.page}
          setTotalPages={setTotalPages}
          setTotalElements={setTotalElements}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      )}
    </Layout>
  );
};

export default AdsList;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { CustomAdsDepartmentFilters } from "../../components/Context/AdsDepartmentFilters";
import { AiOutlineRight } from "react-icons/ai";

import useWindowSize from "../../hooks/useWindowSize";
import "./SubHeader.scss";
import { CustomAdsFilters } from "../Context/AdsFilters";
import { getAdsByFilter } from "../../api/ads.api";
import { CustomRequestsFilters } from "../Context/RequestsFilters";
import { getRequestsByFilter } from "../../api/requests.api";
import { useLocation } from "react-router-dom";

const SubHeader = ({
  title,
  titleBreadcrumb,
  underTitle,
  list,
  location,
  setter,
  setTotalPages,
  setTotalElements,
}) => {
  const { departmentFilter, storeDepartmentFilter } = useContext(
    CustomAdsDepartmentFilters
  );
  const size = useWindowSize();
  const currentLocation = useLocation();

  const customAdsFilters = useContext(CustomAdsFilters);
  const customRequestsFilters = useContext(CustomRequestsFilters);

  const adsFilters = customAdsFilters.adsFilters;
  const filterSetter = customAdsFilters.storeAdsFilters;

  const requestsFilters = customRequestsFilters.requestsFilters;
  const filterPetitionSetter = customRequestsFilters.storeRequestsFilters;

  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const checkIfIncludes = (origin, text) => {
    if (typeof origin === "object") {
      origin.address = Object.values(origin.address);
      origin = `${origin.address.join(" ")}`;
    }
    return origin
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
  };

  const customFilter = async (text, department) => {
    if (title === "Anuncios" && (text?.length >= 3 || text?.length === 0)) {
      const searchFilters = {
        ...adsFilters,
        search: text,
        department: department,
        page: 1,
      };
      const response = await getAdsByFilter(searchFilters);
      if (response && setter !== undefined) {
        setter(response.ads);
        setTotalPages(response.pageInfo.totalPages);
        setTotalElements(response.pageInfo.totalElements);
      }
    } else if (
      title === "Peticiones" &&
      (text?.length >= 3 || text?.length === 0) &&
      setter !== undefined
    ) {
      const searchFilters = {
        ...requestsFilters,
        search: text,
        department: department,
        page: 1,
      };
      const response = await getRequestsByFilter(searchFilters);
      if (response && setter !== undefined) {
        setter(response.requests);
        setTotalPages(response.pageInfo.totalPages);
        setTotalElements(response.pageInfo.totalElements);
      }
    } else if (title === "Consultores" && setter !== undefined) {
      const consultantsFiltered = list?.filter((consultant) => {
        if (
          checkIfIncludes(consultant.fullName, text) ||
          checkIfIncludes(consultant.consultantEmail, text)
        )
          return consultant;
      });
      setter(consultantsFiltered);
    } else if (title === "Contactos" && setter !== undefined) {
      const contactFiltered = list?.filter((contact) => {
        if (
          checkIfIncludes(contact.fullName, text) ||
          checkIfIncludes(contact.email, text) ||
          checkIfIncludes(contact.company, text) ||
          checkIfIncludes(contact.contactMobileNumber, text)
        )
          return contact;
      });
      setter(contactFiltered);
    }
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchValue?.length >= 3 || searchValue?.length === 0) {
      if (currentLocation.pathname === "/anuncios") {
        const newTimeout = setTimeout(() => {
          customFilter(searchValue, adsFilters.department);
        }, 500);
        setSearchTimeout(newTimeout);
      } else if (currentLocation.pathname === "/peticiones") {
        const newTimeout = setTimeout(() => {
          customFilter(searchValue, requestsFilters.department);
        }, 500);
        setSearchTimeout(newTimeout);
      } else {
        customFilter(searchValue, "");
      }
    }

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchValue, adsFilters.department, requestsFilters.department]);

  const handleChangeDepartament = (e) => {
    const department = e.target.innerText;
    filterSetter({
      ...adsFilters,
      department: department,
    });
    customFilter(searchValue, department);
  };

  const handleSearchChange = (ev) => {
    const value = ev.target.value;
    setSearchValue(value);
  };

  return (
    <div className="subHeader">
      <div className="subHeader__title noDisplay">
        <h2>{title}</h2>
        {titleBreadcrumb && (
          <p>
            <AiOutlineRight fontSize="0.9em" style={{ marginRight: 10 }} />{" "}
            {titleBreadcrumb}
          </p>
        )}
      </div>

      <div className="subHeader__subtitle">
        {underTitle ? (
          <div className="subHeader__subtitle-item">
            <span>{underTitle}</span>
          </div>
        ) : (
          <>
            <div className="subHeader__subtitle-item noDisplay">
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {currentLocation.pathname === "/consultores" ||
                currentLocation.pathname === "/contactos"
                  ? `${list.length} elementos`
                  : `${list} elementos`}
                {size > 1141 && "· Ordenado por fecha de última modificación"}
              </span>
            </div>
            <div
              style={
                size > 425
                  ? { display: "flex" }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              {window.location.pathname.includes(`anuncios`) &&
                !window.location.pathname.includes(`anuncios/`) && (
                  <div className="subHeader__subtitle-item subHeader__filter-box">
                    <button
                      className={
                        adsFilters.department === "Patrimonio"
                          ? "subHeader__filter-box--item__active"
                          : "subHeader__filter-box--item"
                      }
                      onClick={handleChangeDepartament}
                    >
                      <p>Patrimonio</p>
                    </button>
                    <button
                      className={
                        adsFilters.department === "Residencial"
                          ? "subHeader__filter-box--item subHeader__filter-box--item__border__active"
                          : "subHeader__filter-box--item subHeader__filter-box--item__border"
                      }
                      onClick={handleChangeDepartament}
                    >
                      <p>Residencial</p>
                    </button>
                    <button
                      className={
                        adsFilters.department === "Todos"
                          ? "subHeader__filter-box--item__active"
                          : "subHeader__filter-box--item"
                      }
                      onClick={handleChangeDepartament}
                    >
                      <p>Todos</p>
                    </button>
                  </div>
                )}
              <div
                style={
                  size < 425
                    ? {
                        display: "flex",
                        width: "90vw",
                        justifyContent: "space-between",
                      }
                    : {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }
                }
              >
                <div className="subHeader__subtitle_searchBarContainer">
                  <i className="pi pi-search a" />
                  <InputText
                    id="search"
                    onChange={handleSearchChange}
                    placeholder="Buscar en esta lista un elemento"
                    style={
                      size > 425
                        ? { width: "30rem", marginRight: "1vw" }
                        : { width: "20rem" }
                    }
                  />
                </div>
                {!window.location.pathname.includes(
                  `${title.toLowerCase()}/`
                ) && (
                  <button
                    type="button"
                    className="subHeader__btn"
                    onClick={location}
                  >
                    Nuevo
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubHeader;

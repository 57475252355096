import React, { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";

import "./CategoriesImages.scss";

const CategoriesImages = ({ image, imageName }) => {
  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  return (
    <>
      <div className="WebHomePage">
        <p className="WebHomePage__title">
          <span style={{ fontWeight: "bold" }}>GV {imageName}</span>
        </p>
        <img
          className="WebHomePage__image"
          src={image}
          alt="Imagen principal de la home page"
        />
      </div>
      {/* <BiEdit
            className="cursor"
            style={{
              marginRight: 7,
              transform: "scale(125%)",
              verticalAlign: "middle",
            }}
            onClick={() => setOpenEditImageForm(true)}
          /> */}
    </>
  );
};

export default CategoriesImages;

import { useEffect, useState } from "react";
import LayoutWeb from "../LayoutWeb/layoutWeb";
import TitleAndImageEditForm from "../../components/EditWebData/HomePage/TitleAndImageForm";
import { getAllWebData } from "../../api/webData.api";
import TitleAndImage from "../../components/EditWebData/HomePage/TitleAndImage";
import CategoriesImages from "../../components/EditWebData/HomePage/CategoriesImages";
import EditHomeImages from "../../components/EditWebData/HomePage/EditIHomeImage";
import SectionsEditForm from "../../components/EditWebData/HomePage/SectionsEditForm";
import ContactEditForm from "../../components/EditWebData/HomePage/ContactEditForm";
import "./WebHome.scss";

const WebHome = () => {
  const [webData, setWebData] = useState([]);
  const [openEditHomeTitleForm, setOpenEditHomeTitleForm] = useState(false);
  const [openEditHomeImageForm, setOpenEditHomeImageForm] = useState(false);
  const [openEditHomeServices, setOpenEditHomeServicesForm] = useState(false);
  const [openEditContactForm, setOpenEditContactForm] = useState(false);
  const [hideContent, setHideContent] = useState(false);
  const [imageName, setImageName] = useState("");
  async function fetchGetAllWebData() {
    const webData = await getAllWebData();
    setWebData(webData[0]);
  }
  useEffect(() => {
    fetchGetAllWebData();
  }, []);
  return (
    <LayoutWeb subTitle="Home page">
      <div className="HomePageTitle">
        {!openEditHomeTitleForm && (
          <>
            <h1>Home page</h1>
          </>
        )}
      </div>
      {openEditHomeTitleForm && (
        <TitleAndImageEditForm
          setOpenEditHomeTitleForm={setOpenEditHomeTitleForm}
          webData={webData}
          setWebData={setWebData}
          setHideContent={setHideContent}
        />
      )}
      {openEditHomeImageForm && (
        <EditHomeImages
          setOpenEditHomeImageForm={setOpenEditHomeImageForm}
          webData={webData}
          setWebData={setWebData}
          imageName={imageName}
          setHideContent={setHideContent}
        />
      )}
      {openEditHomeServices && (
        <SectionsEditForm
          setOpenEditHomeServicesForm={setOpenEditHomeServicesForm}
          webData={webData}
          setWebData={setWebData}
          imageName={imageName}
          setHideContent={setHideContent}
        />
      )}
      {openEditContactForm && (
        <ContactEditForm
          setOpenEditContactForm={setOpenEditContactForm}
          webData={webData}
          setWebData={setWebData}
          // imageName={imageName}
          setHideContent={setHideContent}
        />
      )}

      {!hideContent && webData?.length !== 0 ? (
        <>
          <h2 className="LayoutWeb__contain__children__title">
            Título e imagen
          </h2>

          <div className="HomePageContent">
            <TitleAndImage
              image={webData?.portraidImage}
              title={webData?.mainTitle}
            />
            <button
              tpye="button"
              className="subHeader__btn "
              onClick={() => {
                setOpenEditHomeTitleForm(true);
                setHideContent(true);
              }}
            >
              Editar
            </button>
            {/* {webData.map((catalog, i) => (
            <>
              <CatalogCard
                catalog={catalog}
                webData={webData}
                setWebData={setWebData}
                index={i}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => setOpenEditHomeTitleForm(true)}
              >
                Editar
              </button>
            </>
          ))} */}
          </div>
          <h2 className="LayoutWeb__contain__children__title">Categorías</h2>
          <div className="HomePageCategories">
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.categoriesImages.residential}
                imageName={"Residencial"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Residencial");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.categoriesImages.patrimonial}
                imageName={"Patrimonial"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Patrimonial");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.categoriesImages.art}
                imageName={"Arte"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Arte");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.categoriesImages.catalog}
                imageName={"Catálogo"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Catálogo");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
          </div>
          <h2 className="LayoutWeb__contain__children__title">
            Otras categorías
          </h2>
          <div className="HomePageCategories">
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.otherCategoriesImages.coast}
                imageName={"Costa"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Costa");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.otherCategoriesImages.rustic}
                imageName={"Rústico"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Rústico");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.otherCategoriesImages.singular}
                imageName={"Act. Singulares"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Act. Singulares");
                  setOpenEditHomeImageForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
          </div>
          <h2 className="LayoutWeb__contain__children__title">Servicios</h2>
          <div className="HomePageCategories">
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.sections?.interiorims?.image}
                imageName={"Interiorismo"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Interiorismo");
                  setOpenEditHomeServicesForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.sections?.sell?.image}
                imageName={"Venda con nosotros"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Ventas");
                  setOpenEditHomeServicesForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
            <div className="HomePageContent">
              <CategoriesImages
                image={webData?.sections?.offices?.image}
                imageName={"Nuestras oficinas"}
              />
              <button
                tpye="button"
                className="subHeader__btn "
                onClick={() => {
                  setImageName("Oficinas");
                  setOpenEditHomeServicesForm(true);
                  setHideContent(true);
                }}
              >
                Editar
              </button>
            </div>
          </div>
          {/* <h2 className="LayoutWeb__contain__children__title">Contacto</h2>
          <div className="HomePageContent">
            <div className="ContactSection">
              <p className="ContactSection__title">
                <span style={{ fontWeight: "bold" }}>Título: </span>
                {webData?.talkWithUs.title}
              </p>
              <p className="ContactSection__title">
                <span style={{ fontWeight: "bold" }}>Correo: </span>
                {webData?.talkWithUs.email}
              </p>
              <p className="ContactSection__title">
                <span style={{ fontWeight: "bold" }}>Direcciones: </span>
              </p>
              {webData?.talkWithUs.directions.map((direction) => (
                <p className="ContactSection__item">{direction}</p>
              ))}
              <p className="ContactSection__title">
                <span style={{ fontWeight: "bold" }}>Teléfonos: </span>
              </p>
              {webData?.talkWithUs.phones.map((phone) => (
                <p className="ContactSection__item">{phone}</p>
              ))}
              <p className="ContactSection__title">
                <span style={{ fontWeight: "bold" }}>Texto botón: </span>
                {webData?.talkWithUs.contactButton}
              </p>
            </div>
            <button
              tpye="button"
              className="subHeader__btn "
              onClick={() => {
                setOpenEditContactForm(true);
                setHideContent(true);
              }}
            >
              Editar
            </button>
          </div> */}
        </>
      ) : null}
    </LayoutWeb>
  );
};

export default WebHome;

import React from "react";
import "./Pagination.scss";
import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from "react-icons/ai";

const Pagination = ({ currentPage, totalPages, paginate }) => {
  // Navegación a la página anterior
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  // Navegación a la página siguiente
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  return (
    <div className="Pagination">
      <button onClick={goToPreviousPage} disabled={currentPage <= 1}>
        <AiOutlineDoubleLeft />
      </button>
      <small>
        Página {currentPage} de {totalPages}
      </small>
      <button onClick={goToNextPage} disabled={currentPage >= totalPages}>
        <AiOutlineDoubleRight />
      </button>
    </div>
  );
};

export default Pagination;

import React, { useEffect, useState } from "react";
import "./TitleAndImage.scss";

const TitleAndImage = ({ image, title }) => {
  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  return (
    <div className="WebHomePage">
      <p className="WebHomePage__title">
        <span className="WebHomePage__title__bold">Título:</span> {title}
      </p>
      <img
        className="WebHomePage__image"
        src={image}
        alt="Imagen principal de la home page"
      />
    </div>
  );
};

export default TitleAndImage;

import React from "react";
import { NavLink } from "react-router-dom";
import { Ads } from "../../icons/index.js";
import "./WebManagementNavbar.scss";

const WebManagementNavbar = () => {
  return (
    <nav className="webManagementNavbar">
      <div className="webManagementNavbar__menu">
        <ul className="webManagementNavbar__menu__list">
          <NavLink to="/webhomepage">
            <li className="webManagementNavbar__menu__list__item">
              <div>
                <Ads className="navigate__link--icon" />
                <span>Home</span>
              </div>
            </li>
          </NavLink>
          <NavLink to="/catalogos">
            <li className="webManagementNavbar__menu__list__item">
              <div>
                <Ads className="navigate__link--icon" />
                <span>Catálogos</span>
              </div>
            </li>
          </NavLink>
          <NavLink to="/servicios">
            <li className="webManagementNavbar__menu__list__item">
              <div>
                <Ads className="navigate__link--icon" />
                <span>Servicios</span>
              </div>
            </li>
          </NavLink>
          <NavLink to="/contacto">
            <li className="webManagementNavbar__menu__list__item">
              <div>
                <Ads className="navigate__link--icon" />
                <span>Contacto</span>
              </div>
            </li>
          </NavLink>
        </ul>
      </div>
    </nav>
  );
};

export default WebManagementNavbar;

import { useEffect, useState, useContext } from "react";
import { FilterService } from "primereact/api";
import { CustomAdsFilters } from "../../components/Context/AdsFilters";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { MultiSelect } from "primereact/multiselect";
import { Link } from "react-router-dom";
import { BsCloudArrowUp } from "react-icons/bs";
import * as moment from "moment";
import {
  adStatusOptions,
  gvOperationCloseOptions,
  adTypeOptions,
  adBuildingTypeOptions,
  residentialZones,
  patrimonialZones,
  othersZones,
} from "../../constants/ads-constants";
import "moment/locale/es";
import "./AdsTable.scss";
import "./DataTableDemo.scss";
import "./AdsTable.scss";
import { getAdsByFilter } from "../../api/ads.api";

const AdsTable = ({
  ads,
  setAds,
  setTotalElements,
  setTotalPages,
  setSortField,
  setSortOrder,
  sortField,
  sortOrder,
}) => {
  const [adsFormated, setAdsFormated] = useState([]);
  const customAdsFilters = useContext(CustomAdsFilters);
  const adsFilters = customAdsFilters.adsFilters;
  const filterSetter = customAdsFilters.storeAdsFilters;

  const [loader, setLoader] = useState(true);
  const [filters, setFilters] = useState({
    adType: { value: adsFilters.adType, matchMode: "INCLUDES" },
    adBuildingType: { value: adsFilters.adBuildingType, matchMode: "INCLUDES" },
    gvOperationClose: {
      value: adsFilters.gvOperationClose,
      matchMode: "INCLUDES",
    },
    adStatus: { value: adsFilters.adStatus, matchMode: "INCLUDES" },
    zone: { value: adsFilters.zones?.name, matchMode: "INCLUDES" },
  });
  const [allZones, setAllZones] = useState([]);
  const [gettingZones, setGettingZones] = useState(false);

  useEffect(() => {
    setGettingZones(true);
    residentialZones.then((res) => {
      const groupedZones = [];
      const residentialZones = {
        label: "Residencial",
        items: res,
      };

      groupedZones.push(residentialZones);
      patrimonialZones.then((resp) => {
        const patrimonialZones = {
          label: "Patrimonio",
          items: resp,
        };
        groupedZones.push(patrimonialZones);
        othersZones.then((respon) => {
          const othersZones = {
            label: "Otros",
            items: respon,
          };
          groupedZones.push(othersZones);
          setAllZones(groupedZones);
          setGettingZones(false);
        });
      });
    });
  }, []);

  FilterService.register("INCLUDES", (value, filter) => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }
    let newFilter = filter.map((e) => e.name);

    if (newFilter.length === 0) return true;
    if (newFilter.includes("Ninguno")) return true;
    else {
      for (let elem of newFilter) {
        if (value.includes(elem)) return true;
      }
    }
  });

  useEffect(() => {
    if (ads.length !== 0) {
      formatData(ads);
    }
  }, [ads]);

  useEffect(() => {
    if (setSortField && setSortOrder) {
      setSortField(adsFilters?.sortField);
      setSortOrder(adsFilters?.sortOrder === "ASC" ? 1 : -1);
    }
  }, [adsFilters.sortField, adsFilters.sortOrder, setSortField, setSortOrder]);

  const getAdsFilterByQueryUser = async () => {
    const response = await getAdsByFilter({
      ...adsFilters,
      sortField,
      sortOrder: sortOrder === 1 ? "ASC" : "DESC",
    });

    if (response) {
      setAds(response.ads);
      setTotalElements(response.pageInfo.totalElements);
      setTotalPages(response.pageInfo.totalPages);
    }
  };

  const handleGvOperationClose = async (options, e) => {
    adsFilters.gvOperationClose = e.value;
    filterSetter(adsFilters);
    options.filterApplyCallback(e.value);
    getAdsFilterByQueryUser();
  };

  const adTypeFilterTemplate = (options) => {
    return (
      <>
        <MultiSelect
          value={adsFilters.adType}
          options={adTypeOptions}
          itemTemplate={itemsTemplate}
          onChange={(e) => {
            adsFilters.adType = e.value;
            filterSetter(adsFilters);
            options.filterApplyCallback(e.value);
            getAdsFilterByQueryUser();
          }}
          optionLabel="name"
          placeholder="Todos"
          className="p-column-filter"
        />
      </>
    );
  };

  const adBuildingTypeFilterTemplate = (options) => {
    return (
      <>
        <MultiSelect
          value={adsFilters.adBuildingType}
          options={adBuildingTypeOptions}
          itemTemplate={itemsTemplate}
          onChange={(e) => {
            adsFilters.adBuildingType = e.value;
            filterSetter(adsFilters);
            options.filterApplyCallback(e.value);
            getAdsFilterByQueryUser();
          }}
          optionLabel="name"
          placeholder="Todos"
          className="p-column-filter"
        />
      </>
    );
  };

  const adBuildingZoneFilterTemplate = (options) => {
    return (
      <>
        <MultiSelect
          value={adsFilters.zone}
          options={gettingZones ? [] : allZones}
          itemTemplate={itemsTemplate}
          onChange={(e) => {
            adsFilters.zone = e.value;
            filterSetter(adsFilters);
            options.filterApplyCallback(e.value);
            getAdsFilterByQueryUser();
          }}
          filter
          optionLabel="name"
          optionGroupLabel="label"
          optionGroupChildren="items"
          placeholder="Todos"
          className="p-column-filter"
        />
      </>
    );
  };

  const adStatusFilterTemplate = (options) => {
    return (
      <>
        <MultiSelect
          value={adsFilters.adStatus}
          options={adStatusOptions}
          itemTemplate={itemsTemplate}
          onChange={(e) => {
            adsFilters.adStatus = e.value;
            filterSetter(adsFilters);
            options.filterApplyCallback(e.value);
            getAdsFilterByQueryUser();
          }}
          optionLabel="name"
          placeholder="Todos"
          className="p-column-filter"
        />
      </>
    );
  };

  const gvOperationCloseFilterTemplate = (options) => {
    return (
      <>
        <MultiSelect
          value={adsFilters.gvOperationClose}
          options={gvOperationCloseOptions}
          itemTemplate={itemsTemplate}
          onChange={(e) => handleGvOperationClose(options, e)}
          optionLabel="name"
          placeholder="Todos"
          className="p-column-filter"
        />
      </>
    );
  };

  const itemsTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text ">{option.name}</span>
      </div>
    );
  };

  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Fecha de modificación"
          rowSpan={2}
          sortable
          field="updatedAt"
          style={{ width: "0%" }}
        />
        <Column header="Referencia" rowSpan={2} style={{ width: "0%" }} />
        <Column header="Dirección" rowSpan={2} style={{ width: "0.75%" }} />
        <Column header="Título" rowSpan={2} style={{ width: "1.5%" }} />
        <Column
          header="Estado anuncio"
          rowSpan={2}
          style={{ width: "0.10%" }}
          filter
          filterField="adStatus"
          filterElement={adStatusFilterTemplate}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Cierre operación GV"
          rowSpan={2}
          style={{ width: "0%" }}
          filter
          filterField="gvOperationClose"
          filterElement={gvOperationCloseFilterTemplate}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column header="Precio" colSpan={2} />
        <Column header="Superficie" rowSpan={2} style={{ width: "0%" }} />
        <Column
          header="Tipo de inmueble"
          rowSpan={2}
          style={{ width: "0.15%" }}
          filter
          filterField="adBuildingType"
          filterElement={adBuildingTypeFilterTemplate}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Tipo de anuncio"
          rowSpan={2}
          style={{ width: "0%" }}
          filter
          filterElement={adTypeFilterTemplate}
          filterField="adType"
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Zona"
          rowSpan={2}
          style={{ width: "0.15%" }}
          filter
          filterField="zone"
          filterElement={adBuildingZoneFilterTemplate}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column header="Propietario" rowSpan={2} style={{ width: "0.15%" }} />
        <Column header="Consultor" rowSpan={2} style={{ width: "0.15%" }} />
      </Row>
      <Row>
        <Column
          header="Venta"
          colSpan={1}
          style={{ width: "0.08%" }}
          field="sale.saleValue"
          sortable
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          header="Alquiler"
          colSpan={1}
          style={{ width: "0.08%" }}
          field={"rent.rentValue"}
          sortable
          showApplyButton={false}
          showClearButton={false}
        />
      </Row>
    </ColumnGroup>
  );

  const formatCurrency = (value) => {
    return value
      ? Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
      : value;
  };

  const numberWithDots = (x) => {
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €/mes"
    );
  };

  const surfaceBodyTemplate = (rowData) => {
    if (rowData?.buildSurface !== 0) {
      return (
        <p>
          {rowData?.buildSurface
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
          m<sup>2</sup>
        </p>
      );
    }
  };

  const referenceBodyTemplate = (rowData) => {
    return (
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        to={`/anuncios/${rowData?._id}`}
      >
        {rowData?.adReference}
      </Link>
    );
  };

  const titleBodyTemplate = (rowData) => {
    return (
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        to={`/anuncios/${rowData?._id}`}
      >
        {rowData?.title}
      </Link>
    );
  };

  const directionBodyTemplate = (rowData) => {
    return (
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        to={`/anuncios/${rowData?._id}`}
      >
        {rowData?.adDirection}
      </Link>
    );
  };

  const saleBodyTemplate = (rowData) => {
    if (rowData?.sale !== null && rowData?.sale !== undefined) {
      if (rowData?.sale?.saleValue !== 0)
        return formatCurrency(rowData?.sale?.saleValue);
    } else return "";
  };

  const rentBodyTemplate = (rowData) => {
    if (rowData?.rent !== null && rowData?.rent !== undefined) {
      if (rowData?.rent?.rentValue !== 0)
        return numberWithDots(rowData?.rent?.rentValue);
    } else return "";
  };

  const contactBodyTemplate = (rowData) => {
    return (
      <Link to={`/contactos/${rowData?.owner?._id}`}>
        {rowData?.owner?.fullName}
      </Link>
    );
  };

  const formatData = (ads) => {
    const newAds = ads.map((ad) => {
      if (typeof ad.adDirection === "object") {
        const zoneNames = [];
        ad.adDirection.address.street =
          ad?.adDirection?.address?.street?.trim();
        ad.adDirection.address = Object.values(ad?.adDirection?.address);
        ad.adDirection = ad?.adDirection?.address?.join(" ");
        ad.adBuildingType = ad?.adBuildingType?.sort().join(", ");
        ad.adType = ad?.adType?.sort().join(", ");
        ad.zone.map((zone) => {
          zoneNames.push(zone.name);
          return zoneNames;
        });
        ad.zone = zoneNames.join(", ");
      }
      return ad;
    });
    if (newAds) {
      setAdsFormated(newAds);
      setLoader(false);
    }
  };

  const handleUpdateAt = (rowData) => {
    return moment(rowData.updatedAt).locale("es-ES").format("L");
  };

  const onSort = async (e) => {
    const newSortField = e.sortField;
    const newSortOrder = e.sortOrder === 1 ? "ASC" : "DESC";

    setSortField(newSortField);
    setSortOrder(e.sortOrder);

    const updatedAdsFilters = {
      ...adsFilters,
      sortField: newSortField,
      sortOrder: newSortOrder,
    };

    filterSetter(updatedAdsFilters);

    const response = await getAdsByFilter(updatedAdsFilters);

    if (response && response.ads) {
      setAds(response.ads);
    }
  };

  return (
    <>
      {!loader ? (
        <DataTable
          dataKey="_id"
          headerColumnGroup={headerGroup}
          value={ads?.length !== 0 ? adsFormated : []}
          removableSort
          filterDisplay="menu"
          showGridlines
          filters={filters}
          rows={100}
          onSort={(e) => onSort(e)}
          sortField={sortField}
          sortOrder={sortOrder}
          emptyMessage="No se ha encontrado ningún anuncio"
        >
          <Column field="updatedAt" body={handleUpdateAt} />
          <Column field="adReference" body={referenceBodyTemplate} />
          <Column field="adDirection" body={directionBodyTemplate} />
          <Column field="title" body={titleBodyTemplate} />
          <Column field="adStatus" />
          <Column field="gvOperationClose" />
          <Column field="sale.saleValue" body={saleBodyTemplate} />
          <Column field="rent.rentValue" body={rentBodyTemplate} />
          <Column field="buildSurface" body={surfaceBodyTemplate} />
          <Column field="adBuildingType" />
          <Column field="adType" />
          <Column field="zone" />
          <Column field="owner.fullName" body={contactBodyTemplate} />
          <Column field="consultant.fullName" />
        </DataTable>
      ) : (
        <div style={{ height: 200 }}>
          <p style={{ lineHeight: 4 }}>No ha creado ningún anuncio </p>
          <BsCloudArrowUp fontSize="2.5em" />
        </div>
      )}
    </>
  );
};

export default AdsTable;

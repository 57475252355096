import React, { useState } from "react";
import storage from "../../services/storage";

export const CustomAdsFilters = React.createContext([]);

const INITIAL_ADS_FILTERS = storage.get("adsFilters");

const AdsFilters = (props) => {
  const [adsFilters, setFilters] = useState(
    INITIAL_ADS_FILTERS.length === 0
      ? {
          adType: [],
          adBuildingType: [],
          gvOperationClose: [],
          adStatus: [],
          zones: [],
          department: "Todos",
          sortField: "updatedAt",
          sortOrder: "DESC",
          zone: [],
          page: 1,
        }
      : INITIAL_ADS_FILTERS
  );

  const storeAdsFilters = (filters) => {
    setFilters(filters);
    storage.set("adsFilters", filters);
  };

  const deleteAdFilters = () => {
    setFilters("");
    storage.removeItem("adsFilters");
  };

  return (
    <CustomAdsFilters.Provider
      value={{
        adsFilters: adsFilters,
        storeAdsFilters,
        deleteAdFilters,
        setFilters,
      }}
    >
      {props.children}
    </CustomAdsFilters.Provider>
  );
};

export default AdsFilters;

import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import "./MarketingCampaignEditForm.scss";
import { updateMarketingCampaign } from "../../api/marketingCampaigns.api";
const MarketingCampaignEditForm = ({
  setOpenEditCampaignForm,
  campaigns,
  setCampaigns,
  campaign,
  index,
}) => {
  const [files, setFiles] = useState([]);
  // const [formFilesError, setFormFilesError] = useState(false);
  const [formDescriptionError, setFormDescriptionError] = useState(false);
  const [formTitleError, setFormTitleError] = useState(false);
  const SubmitForm = async (e) => {
    e.preventDefault();
    setFormTitleError(false);
    // setFormFilesError(false);
    setFormDescriptionError(false);
    if (e.target.title.value === "") {
      setFormTitleError(true);
    }
    if (e.target.description.value === "") {
      setFormDescriptionError(true);
    }
    // if (files.length < 2) {
    //   setFormFilesError(true);
    // }
    const fileData = new FormData();
    fileData.append("title", e.target.title.value);
    fileData.append("description", e.target.description.value);
    for (let file of files) {
      fileData.append("campaingLogo", file);
    }
    // for (let item of fileData) {
    //   console.log(item);
    // }
    const uploadCampaign = await updateMarketingCampaign(
      fileData,
      campaign._id
    );
    if (Object.keys(uploadCampaign).includes("_id")) {
      const newCampaign = campaigns.toSpliced(index, 1, uploadCampaign);
      setCampaigns(newCampaign);
      setOpenEditCampaignForm(false);
    }
  };
  const handleUploadPortraidImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    const pdfIndex = filesArray.findIndex((file) => !file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      filesArray.unshift(file);
    }
    setFiles(filesArray);
  };

  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  return (
    <form onSubmit={SubmitForm} className="MarketingCampaignEditForm">
      <div className="MarketingCampaignEditForm__title">
        <h1>Edición campaña</h1>
        <IoIosClose
          className="MarketingCampaignEditForm__title__close"
          onClick={() => setOpenEditCampaignForm(false)}
        />
      </div>
      <div className="MarketingCampaignEditForm__item">
        <label htmlFor="title">Título</label>
        <input
          className="yearInput"
          type="text"
          name="title"
          id="title"
          defaultValue={campaign.title}
        />
        {formTitleError && (
          <p className="error">*Por favor, rellene este campo</p>
        )}
      </div>
      <div className="MarketingCampaignEditForm__item">
        <label htmlFor="portraidImage">Imagen de campaña</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadPortraidImage}
        />
      </div>
      <div className="MarketingCampaignNewForm__item">
        <label htmlFor="description">Descripción</label>
        <textarea
          name="description"
          id="description"
          defaultValue={campaign.description}
          rows={8}
          onChange={() => setFormDescriptionError(false)}
        />
        {formDescriptionError && (
          <p className="error">*Por favor, rellene este campo</p>
        )}
      </div>
      <div className="MarketingCampaignEditForm__buttons">
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
        <button
          tpye="button"
          className="buttonFormCancel"
          onClick={() => setOpenEditCampaignForm(false)}
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default MarketingCampaignEditForm;

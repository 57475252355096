import React from "react";
import { NavLink } from "react-router-dom";
import { Ads } from "../../icons/index.js";
import "./MarketingCampaignsNavbar.scss";

const MarketingCampaignsNavbar = () => {
  return (
    <nav className="marketingCampaignsNavbar">
      <div className="marketingCampaignsNavbar__menu">
        <ul className="marketingCampaignsNavbar__menu__list">
          <NavLink to="/campaigns">
            <li className="marketingCampaignsNavbar__menu__list__item">
              <div>
                <Ads className="navigate__link--icon" />
                <span>Campañas</span>
              </div>
            </li>
          </NavLink>
          <NavLink to="/campaigns-send">
            <li className="marketingCampaignsNavbar__menu__list__item">
              <div>
                <Ads className="navigate__link--icon" />
                <span>Envíos</span>
              </div>
            </li>
          </NavLink>
        </ul>
      </div>
    </nav>
  );
};

export default MarketingCampaignsNavbar;

import { useState } from "react";
import { BiEdit } from "react-icons/bi";
import noImageBackground from "./assetsServiceCard/sectionWithoutImage.png";
import ServicesEditForm from "../ServicesEditForm/ServicesEditForm";
import "./ServicesCard.scss";
import ServicesEditForm2 from "../ServicesEditForm/ServiceEditForm2";

const ServicesCard = ({
  service,
  setServices,
  webData,
  setHideContent,
  imageName,
}) => {
  const [openEditServicesForm, setOpenEditServicesForm] = useState(false);

  const investmentSection = webData.services.investment.title === service.title;
  const assetManagementSection =
    webData.services.assetManagement.title === service.title;
  const commercializationSection =
    webData.services.commercialization.title === service.title;

  return (
    <div className="ServicesCard">
      <p className="ServicesCard__text">{service?.title}</p>
      <img
        className="ServicesCard__image"
        src={service?.image ? service.image : noImageBackground}
        alt={`Imagen del servicio ${service?.title}`}
      />

      <div className="ServicesCard__buttons">
        <BiEdit
          className="cursor"
          style={{
            marginRight: 7,
            transform: "scale(125%)",
            verticalAlign: "middle",
          }}
          onClick={() => setOpenEditServicesForm(true)}
        />
      </div>
      {openEditServicesForm &&
        (investmentSection ||
        assetManagementSection ||
        commercializationSection ? (
          <ServicesEditForm2
            setOpenEditServicesForm={setOpenEditServicesForm}
            webData={webData}
            setServices={setServices}
            setHideContent={setHideContent}
            service={service}
            imageName={imageName}
          />
        ) : (
          <ServicesEditForm
            setOpenEditServicesForm={setOpenEditServicesForm}
            webData={webData}
            setServices={setServices}
            setHideContent={setHideContent}
            imageName={imageName}
            service={service}
          />
        ))}
    </div>
  );
};

export default ServicesCard;

import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

import "./CatalogCard.scss";
import { deleteCatalog } from "../../api/catalogs.api";
import CatalogEditForm from "../CatalogEditForm/CatalogEditForm";

const CatalogCard = ({ catalog, catalogs, setCatalogs, index }) => {
  const [openEditCatalogForm, setOpenEditCatalogForm] = useState(false);

  const removeCatalog = async (catalogId) => {
    const remove = await deleteCatalog(catalogId);
    if (remove === "Catálogo borrado de la base de datos") {
      const newCatalogs = catalogs.filter(
        (catalog) => catalog._id !== catalogId
      );
      setCatalogs(newCatalogs);
    }
  };
  return (
    <div className="CatalogCard">
      <img
        className="CatalogCard__image"
        src={catalog.portraidImage}
        alt={`Imagen de portada del catálogo de ${catalog.year}`}
      />
      <p className="CatalogCard__text">Catálogo {catalog.year}</p>
      <div className="CatalogCard__buttons">
        <BiEdit
          className="cursor"
          style={{
            marginRight: 7,
            transform: "scale(125%)",
            verticalAlign: "middle",
          }}
          onClick={() => setOpenEditCatalogForm(true)}
        />
        <FaTrash
          className="cursor"
          style={{
            marginRight: 7,
            transform: "scale(125%)",
            verticalAlign: "middle",
            color: "#2B363D",
          }}
          onClick={() => removeCatalog(catalog._id)}
        />
      </div>
      {openEditCatalogForm && (
        <CatalogEditForm
          catalogs={catalogs}
          setCatalogs={setCatalogs}
          setOpenEditCatalogForm={setOpenEditCatalogForm}
          catalog={catalog}
          index={index}
        />
      )}
    </div>
  );
};

export default CatalogCard;

import { useEffect, useState } from "react";
import LayoutWeb from "../LayoutWeb/layoutWeb";
import { getAllWebData } from "../../api/webData.api";
import ContactEditForm from "../../components/EditWebData/HomePage/ContactEditForm";
import "./Contact.scss";

const Contact = () => {
  const [webData, setWebData] = useState([]);
  const [hideContent, setHideContent] = useState(false);
  const [openEditContactForm, setOpenEditContactForm] = useState(false);

  async function fetchGetAllWebData() {
    const webData = await getAllWebData();

    setWebData(webData[0]);
  }

  useEffect(() => {
    fetchGetAllWebData();
  }, []);
  return (
    <LayoutWeb subTitle="Catálogos">
      <div className="CatalogsTitle">
        <h1>Contacto</h1>
      </div>
      {openEditContactForm && (
        <ContactEditForm
          setOpenEditContactForm={setOpenEditContactForm}
          webData={webData}
          setWebData={setWebData}
          // imageName={imageName}
          setHideContent={setHideContent}
        />
      )}

      {!hideContent && webData?.length !== 0 && (
        <div className="HomePageContent">
          <div className="ContactSection">
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Título home: </span>
              {webData?.talkWithUs.titleHome}
            </p>
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Título contacto: </span>
              {webData?.talkWithUs.titleContact}
            </p>
            <p className="ContactSection__title w50">
              <span style={{ fontWeight: "bold" }}>Descripción contacto: </span>
              {webData?.talkWithUs.descriptionContact}
            </p>
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Correo: </span>
              {webData?.talkWithUs.email}
            </p>
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Direcciones: </span>
            </p>
            {webData?.talkWithUs.directions.map((direction, i) => (
              <p key={i} className="ContactSection__item">
                {direction}
              </p>
            ))}
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Teléfonos: </span>
            </p>
            {webData?.talkWithUs.phones.map((phone, i) => (
              <p key={i} className="ContactSection__item">
                {phone}
              </p>
            ))}
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Texto botón: </span>
              {webData?.talkWithUs.contactButton}
            </p>
            <p className="ContactSection__title">
              <span style={{ fontWeight: "bold" }}>Imagen contacto: </span>
            </p>
            <img
              className="contactImage"
              alt="Imagen de la página de contacto"
              src={webData?.talkWithUs.contactImage}
            />
          </div>
          <button
            tpye="button"
            className="subHeader__btn "
            onClick={() => {
              setOpenEditContactForm(true);
              setHideContent(true);
            }}
          >
            Editar
          </button>
        </div>
      )}
    </LayoutWeb>
  );
};

export default Contact;

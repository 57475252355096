import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import "./TitleAndImageForm.scss";
import {
  updateContactHome,
  updateHomeTextAndImage,
} from "../../../api/webData.api";

const ContactEditForm = ({
  setOpenEditContactForm,
  webData,
  setWebData,
  setHideContent,
}) => {
  const [directions, setDirections] = useState(webData.talkWithUs.directions);
  const [phones, setPhones] = useState(webData.talkWithUs.phones);
  const [files, setFiles] = useState([]);

  const SubmitForm = async (e) => {
    e.preventDefault();
    const fillPhones = phones.filter((phone) => phone !== "");
    const fillDirections = directions.filter((direction) => direction !== "");
    const dataForm = new FormData();
    dataForm.append("titleHome", e.target.talkTitleHome.value);
    dataForm.append("titleContact", e.target.talkTitleContact.value);
    dataForm.append(
      "descriptionContact",
      e.target.talkDescriptionContact.value
    );
    for (let direction of fillDirections) {
      dataForm.append("directions", direction);
    }
    for (let phone of fillPhones) {
      dataForm.append("phones", phone);
    }
    dataForm.append("email", e.target.talkEmail.value);
    dataForm.append("contactButton", e.target.talkContactButton.value);

    for (let file of files) {
      dataForm.append("contactImage", file);
    }
    const uploadContactHome = await updateContactHome(dataForm, webData._id);
    if (Object.keys(uploadContactHome).includes("_id")) {
      webData.talkWithUs.directions = uploadContactHome.talkWithUs.directions;
      webData.talkWithUs.contactImage =
        uploadContactHome.talkWithUs.contactImage;
      webData.talkWithUs.phones = uploadContactHome.talkWithUs.phones;
      webData.talkWithUs.titleHome = uploadContactHome.talkWithUs.titleHome;
      webData.talkWithUs.titleContact =
        uploadContactHome.talkWithUs.titleContact;
      webData.talkWithUs.email = uploadContactHome.talkWithUs.email;
      webData.talkWithUs.contactButton =
        uploadContactHome.talkWithUs.contactButton;
      webData.talkWithUs.descriptionContact =
        uploadContactHome.talkWithUs.descriptionContact;
      setOpenEditContactForm(false);
      setHideContent(false);
    }
  };

  const handleUploadPortraidImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    const pdfIndex = filesArray.findIndex((file) => !file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      filesArray.unshift(file);
    }
    setFiles(filesArray);
  };

  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  return (
    <form onSubmit={SubmitForm} className="CatalogNewForm">
      <div className="CatalogNewForm__title">
        <h1>Editar Contacto</h1>
        <IoIosClose
          className="CatalogNewForm__title__close"
          onClick={() => {
            setOpenEditContactForm(false);
            setHideContent(false);
          }}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Título home</label>
        <input
          type="text"
          name="talkTitleHome"
          id="talkTitleHome"
          defaultValue={webData.talkWithUs.titleHome}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Título contacto</label>
        <input
          type="text"
          name="talkTitleContact"
          id="talkTitleContact"
          defaultValue={webData.talkWithUs.titleContact}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Descripción contacto</label>
        <textarea
          name="talkDescriptionContact"
          id="talkDescriptionContact"
          rows="4"
          cols="50"
          defaultValue={webData.talkWithUs.descriptionContact}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Imagen contacto</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadPortraidImage}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Email</label>
        <input
          type="text"
          name="talkEmail"
          id="talkEmail"
          defaultValue={webData.talkWithUs.email}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">
          Direcciones{" "}
          <span
            onClick={() => {
              setDirections([...directions, ""]);
              console.log(directions);
            }}
          >
            +
          </span>
        </label>
        {directions.map((direction, i) => (
          <input
            type="text"
            name="talkDirection"
            id="talkDirection"
            key={i}
            defaultValue={direction}
            onChange={(e) => directions.splice(i, 1, e.target.value)}
          />
        ))}
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">
          Teléfonos{" "}
          <span
            onClick={() => {
              setPhones([...phones, ""]);
              console.log(phones);
            }}
          >
            +
          </span>
        </label>
        {phones.map((phone, i) => (
          <input
            type="text"
            name="talkPhone"
            id="talkPhone"
            key={i}
            defaultValue={phone}
            onChange={(e) => phones.splice(i, 1, e.target.value)}
          />
        ))}
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Texto botón</label>
        <input
          type="text"
          name="talkContactButton"
          id="talkContactButton"
          defaultValue={webData.talkWithUs.contactButton}
        />
      </div>
      <div className="CatalogNewForm__buttons">
        <button
          tpye="button"
          className="subHeader__btn"
          onClick={() => {
            setOpenEditContactForm(false);
            setHideContent(false);
          }}
        >
          Cancelar
        </button>
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
      </div>
    </form>
  );
};

export default ContactEditForm;

import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import "./TitleAndImageForm.scss";
import { updateHomeTextAndImage } from "../../../api/webData.api";

const TitleAndImageEditForm = ({
  setOpenEditHomeTitleForm,
  webData,
  setWebData,
  setHideContent,
}) => {
  const [files, setFiles] = useState([]);
  const SubmitForm = async (e) => {
    e.preventDefault();
    const fileData = new FormData();
    fileData.append("mainTitle", e.target.homeTitle.value);
    for (let file of files) {
      fileData.append("homeImage", file);
    }
    // for (let item of fileData) {
    //   console.log(item);
    // }
    const uploadHomeImageAndTitle = await updateHomeTextAndImage(
      fileData,
      webData._id
    );
    if (Object.keys(uploadHomeImageAndTitle).includes("_id")) {
      webData.mainTitle = uploadHomeImageAndTitle.mainTitle;
      webData.portraidImage = uploadHomeImageAndTitle.portraidImage;
      setOpenEditHomeTitleForm(false);
      setHideContent(false);
    }
  };
  const handleUploadPortraidImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    const pdfIndex = filesArray.findIndex((file) => !file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      filesArray.unshift(file);
    }
    setFiles(filesArray);
  };

  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  return (
    <form onSubmit={SubmitForm} className="CatalogNewForm">
      <div className="CatalogNewForm__title">
        <h1>Editar Home</h1>
        <IoIosClose
          className="CatalogNewForm__title__close"
          onClick={() => {
            setOpenEditHomeTitleForm(false);
            setHideContent(false);
          }}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="portraidImage">Imagen de portada</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadPortraidImage}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Texto</label>
        <input
          type="text"
          name="homeTitle"
          id="homeTitle"
          defaultValue={webData.mainTitle}
        />
      </div>
      <div className="CatalogNewForm__buttons">
        <button
          tpye="button"
          className="subHeader__btn"
          onClick={() => {
            setOpenEditHomeTitleForm(false);
            setHideContent(false);
          }}
        >
          Cancelar
        </button>
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
      </div>
    </form>
  );
};

export default TitleAndImageEditForm;

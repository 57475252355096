//import { BASE_URL } from "./constants"

const BASE_URL = process.env.REACT_APP_BASE_URL;
const registerUrl = `${BASE_URL}/auth/register`;
const loginUrl = `${BASE_URL}/auth/login`;
const logoutUrl = `${BASE_URL}/auth/logout`;
const checkSessionUrl = `${BASE_URL}/auth/check-session`;
export const registerApi = async (form) => {
  const request = await fetch(registerUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(form),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const response = await request.json();

  if (!request.ok) {
    throw new Error("Error en la peticion", response.message);
  }
  return response;
};

export const loginApi = async (form) => {
  const request = await fetch(loginUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(form),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  let response = {};
  if (request.status === 200) response = await request.json();
  else response.message = "Email o contraseña incorrectos";

  if (!request.ok) {
    throw new Error("Email o contraseña incorrectos", response.message);
  }

  return response;
};

export const logoutApi = async () => {
  const request = await fetch(logoutUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const response = await request.json();

  if (!request.ok) {
    throw new Error(response.message);
  }

  return response;
};

export const checkSession = async () => {
  const request = await fetch(checkSessionUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    credentials: "include",
  });

  const response = await request.json();

  if (!request.ok) {
    return response.message;
  }

  return response;
};

import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

import "./CampaignsCard.scss";
import CatalogEditForm from "../CatalogEditForm/CatalogEditForm";
import { deleteMarketingCampaign } from "../../api/marketingCampaigns.api";
import MarketingCampaignEditForm from "../MarketingCampaignEditForm/MarketingCampaignEditForm";

const CampaignsCard = ({ campaign, campaigns, setCampaigns, index }) => {
  const [openEditCampaignForm, setOpenEditCampaignForm] = useState(false);

  const removeMarketingCampaign = async (campaignId, campaignImage) => {
    const data = {
      toDelete: campaignImage,
    };
    const remove = await deleteMarketingCampaign(campaignId, data);
    if (remove.message === "Campaña borrada de la base de datos") {
      const newCatalogs = campaigns.filter(
        (campaign) => campaign._id !== campaignId
      );
      setCampaigns(newCatalogs);
    }
  };
  return (
    <div className="CampaignsCard">
      <img
        className="CampaignsCard__image"
        src={campaign.image}
        alt={`Imagen de portada del catálogo de ${campaign.title}`}
      />
      <p className="CampaignsCard__text"> {campaign.title}</p>
      <div className="CampaignsCard__buttons">
        <BiEdit
          className="cursor"
          style={{
            marginRight: 7,
            transform: "scale(125%)",
            verticalAlign: "middle",
          }}
          onClick={() => setOpenEditCampaignForm(true)}
        />
        <FaTrash
          className="cursor"
          style={{
            marginRight: 7,
            transform: "scale(125%)",
            verticalAlign: "middle",
            color: "#2B363D",
          }}
          onClick={() => removeMarketingCampaign(campaign._id, campaign.image)}
        />
      </div>
      {openEditCampaignForm && (
        <MarketingCampaignEditForm
          campaigns={campaigns}
          setCampaigns={setCampaigns}
          setOpenEditCampaignForm={setOpenEditCampaignForm}
          campaign={campaign}
          index={index}
        />
      )}
    </div>
  );
};

export default CampaignsCard;

export const isRequiredBuildingType = (buildingType, department )=>{
    /* console.log(department) */
    /* console.log(buildingType) */
    if(department === "Patrimonio" || department === "Residencial"){
        if(buildingType.length === 0) return true;
        else return false;
    }
    else return false;
}
/**
 * Esta función de momento no la estamos usando. Se ha creado para deshabilitar un input 
 * en función del departamento seleccionado.
 * @param {*} department --> El departamento correspondiente
 * @returns boolean que indica si se deshabilita o no el input
 */
export const isDisabledBuildingType = (department) =>{
    if(department === "Patrimonio" || department === "Residencial") return false
    else return true
}

export const departmentUrl = (zone, subzone)=>{
    switch (zone) {
      case 'Residencial':
          return 'residentialItem'
      case 'Patrimonio':
          return 'patrimonialItem'
      case 'Otros':
        return 'residentialItem'
      default:
        return ('residentialItem')    
    }
      }
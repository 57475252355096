import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../Context/AuthUser";
import useWindowSize from "../../hooks/useWindowSize";
import {
  GvreLogo,
  Ads,
  Requests,
  Contacts,
  Consultants,
  WebImage,
  Calendar,
  Settings,
  Ellipse2,
  Ellipse3,
  User,
} from "../../icons/index.js";
import "./Navbar.scss";
import NavbarXS from "./NavbarXS";

const Navbar = ({ title }) => {
  let location = window.location.pathname;
  const size = useWindowSize();
  const { user } = useContext(UserContext);

  return (
    <>
      {size < 880 ? (
        <NavbarXS title={title} />
      ) : (
        <nav className="navigate">
          <div className="navigate__left">
            <ul>
              <div className="navigate__home">
                <li>
                  <GvreLogo className="navigate__link--logo" />
                </li>
                <li>
                  <h1 className="navigate__title">GV Real Estate</h1>
                </li>
              </div>
              <NavLink
                className={
                  location.includes("/anuncios")
                    ? "navigate__route-active"
                    : "navigate__route"
                }
                to="/anuncios"
              >
                <li>
                  <div>
                    <Ads className="navigate__link--icon" />
                    <span className="navigate__link--text">Anuncios</span>
                  </div>
                </li>
              </NavLink>
              <NavLink
                className={
                  location.includes("/peticiones")
                    ? "navigate__route-active"
                    : "navigate__route"
                }
                to="/peticiones"
              >
                <li>
                  <div>
                    <Requests className="navigate__link--icon" />
                    <span className="navigate__link--text">Peticiones</span>
                  </div>
                </li>
              </NavLink>
              <NavLink
                className={
                  location.includes("/contactos")
                    ? "navigate__route-active"
                    : "navigate__route"
                }
                to="/contactos"
              >
                <li>
                  <div>
                    <Contacts className="navigate__link--icon" />
                    <span className="navigate__link--text">Contactos</span>
                  </div>
                </li>
              </NavLink>
              <NavLink
                className={
                  location.includes("/consultores")
                    ? "navigate__route-active"
                    : "navigate__route"
                }
                to="/consultores"
              >
                <li>
                  <div>
                    <Consultants className="navigate__link--icon" />
                    <span className="navigate__link--text">Consultores</span>
                  </div>
                </li>
              </NavLink>
              <NavLink
                className={
                  location.includes("/campaigns")
                    ? "navigate__route-active"
                    : "navigate__route"
                }
                to="/campaigns"
              >
                <li>
                  <div>
                    <Consultants className="navigate__link--icon" />
                    <span className="navigate__link--text">Campañas</span>
                  </div>
                </li>
              </NavLink>
              <NavLink
                className={
                  location.includes("/webhomepage") ||
                  location.includes("/catalogos")
                    ? "navigate__route-active"
                    : "navigate__route"
                }
                to="/webhomepage"
              >
                <li>
                  <div>
                    <WebImage
                      className="navigate__link--icon"
                      style={{ width: "30.76px", height: "26.91px" }}
                    />
                    <span className="navigate__link--text">Gestión web</span>
                  </div>
                </li>
              </NavLink>
              {/* <span className="navigate__route" to="">
                <li>
                  <div>
                    <Calendar className="navigate__link--icon" />
                    <span className="navigate__link--text">Agenda</span>
                  </div>
                </li>
              </span>
              <span className="navigate__route" to="">
                <li>
                  <div>
                    <Settings className="navigate__link--icon" />
                    <span className="navigate__link--text">Configuración</span>
                  </div>
                </li>
              </span> */}
            </ul>
          </div>

          <div className="navigate--user">
            <div className="navigate--user-circle1">
              <div className="navigate--user-circle1-circle2">
                {user.avatar ? (
                  <NavLink to="/">
                    <img
                      src={user.avatar}
                      alt={user.fullName}
                      className="navigate--user-circle1-circle2-avatar"
                    />
                  </NavLink>
                ) : (
                  <NavLink to="/">
                    <User
                      className="navigate--user-circle1-circle2-user"
                      style={{ height: 38, width: 38, border: "none" }}
                    />
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Navbar;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Router } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import "./GoBack.scss";

const GoBack = () => {
  let navigate = useNavigate();
  // const navigate = useNavigate()

  return (
    <button className="GoBack" onClick={() => navigate(-1)}>
      <AiOutlineLeft
        fontSize={"0.8em"}
        style={{ marginRight: 5, color: "#5C5C5C" }}
      />
      Volver
    </button>
  );
};

export default GoBack;

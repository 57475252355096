import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";

import "./CategoriesImages.scss";
import { updateCategoryHomeImage } from "../../../api/webData.api";

const EditHomeImages = ({
  imageName,
  setOpenEditHomeImageForm,
  webData,
  setWebData,
  setHideContent,
}) => {
  const [files, setFiles] = useState([]);

  const setImageFileName = (imageName) => {
    if (imageName === "Residencial") return "residentialImage";
    else if (imageName === "Patrimonial") return "patrimonialImage";
    else if (imageName === "Arte") return "artImage";
    else if (imageName === "Catálogo") return "catalogImage";
    else if (imageName === "Costa") return "coastImage";
    else if (imageName === "Rústico") return "rusticImage";
    else if (imageName === "Act. Singulares") return "singularImage";
    else if (imageName === "Interiorismo") return "interiorismImage";
    else if (imageName === "Ventas") return "sellImage";
    else if (imageName === "Oficinas") return "officesImage";
  };

  const setImageRuoteName = (imageName) => {
    if (imageName === "Residencial") return "residential";
    else if (imageName === "Patrimonial") return "patrimonial";
    else if (imageName === "Arte") return "art";
    else if (imageName === "Catálogo") return "catalog";
    else if (imageName === "Costa") return "coast";
    else if (imageName === "Rústico") return "rustic";
    else if (imageName === "Act. Singulares") return "singular";
    else if (imageName === "Interiorismo") return "interiorism";
    else if (imageName === "Ventas") return "sell";
    else if (imageName === "Oficinas") return "offices";
  };

  const imageFileName = setImageFileName(imageName);
  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  const handleUploadImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    filesArray = files.toSpliced(0, 1, file);
    setFiles(filesArray);
  };
  const SubmitForm = async (e) => {
    e.preventDefault();
    const fileData = new FormData();
    for (let file of files) {
      fileData.append(imageFileName, file);
    }
    // for (let item of fileData) {
    //   console.log(item);
    // }
    const imageRoute = setImageRuoteName(imageName);
    const uploadImage = await updateCategoryHomeImage(
      fileData,
      imageRoute,
      webData._id
    );
    console.log(uploadImage);
    if (Object.keys(uploadImage).includes("_id")) {
      if (imageName === "Residencial") {
        webData.categoriesImages.residential =
          uploadImage.categoriesImages.residential;
      } else if (imageName === "Patrimonial") {
        webData.categoriesImages.patrimonial =
          uploadImage.categoriesImages.patrimonial;
      } else if (imageName === "Arte") {
        webData.categoriesImages.art = uploadImage.categoriesImages.art;
      } else if (imageName === "Catálogo") {
        webData.categoriesImages.catalog = uploadImage.categoriesImages.catalog;
      } else if (imageName === "Costa") {
        webData.otherCategoriesImages.coast =
          uploadImage.otherCategoriesImages.coast;
      } else if (imageName === "Rústico") {
        webData.otherCategoriesImages.rustic =
          uploadImage.otherCategoriesImages.rustic;
      } else if (imageName === "Act. Singulares") {
        webData.otherCategoriesImages.singular =
          uploadImage.otherCategoriesImages.singular;
      }
      setOpenEditHomeImageForm(false);
      setHideContent(false);
    }
  };

  return (
    <form onSubmit={SubmitForm} className="CatalogNewForm">
      <div className="CatalogNewForm__title">
        <h1>Editar {imageName}</h1>
        <IoIosClose
          className="CatalogNewForm__title__close"
          onClick={() => {
            setOpenEditHomeImageForm(false);
            setHideContent(false);
          }}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="portraidImage">Imagen de {imageName}</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadImage}
        />
      </div>
      <div className="CatalogNewForm__buttons">
        <button
          tpye="button"
          className="subHeader__btn"
          onClick={() => {
            setOpenEditHomeImageForm(false);
            setHideContent(false);
          }}
        >
          Cancelar
        </button>
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
      </div>
    </form>
  );
};

export default EditHomeImages;

import React from "react";
import "./layout-web.scss";
import { Navbar } from "../../components";
import useViewport from "../../hooks/useViewport";
import WebManagementNavbar from "../../components/WebManagementNavbar/WebManagementNavbar";

const LayoutWeb = ({ children, subTitle }) => {
  useViewport();

  return (
    <div className="LayoutWeb">
      <Navbar title={subTitle} />
      <div className="LayoutWeb__contain">
        <WebManagementNavbar />

        <div className="LayoutWeb__contain__children">{children}</div>
      </div>
    </div>
  );
};

export default LayoutWeb;

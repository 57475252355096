//import { BASE_URL } from "./constants"
const BASE_URL = process.env.REACT_APP_BASE_URL;
const requestsURL = `${BASE_URL}/catalogs`;

const getAllCatalogs = async () => {
  const request = await fetch(`${requestsURL}/all`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const allCatalogs = await request.json();
  if (!request.ok) {
    throw new Error("Error on fetch", allCatalogs.message);
  }
  return allCatalogs;
};

const createCatalog = async (form) => {
  const request = await fetch(`${requestsURL}/create`, {
    method: "POST",
    credentials: "include",
    body: form,
  });

  const newCatalog = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Catalog", newCatalog.message);
  }
  return newCatalog;
};

const updateCatalog = async (form, catalodId) => {
  const request = await fetch(`${requestsURL}/edit/${catalodId}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const editCatalog = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Catalog", editCatalog.message);
  }
  return editCatalog;
};

const uploadSectionImageCatalog = async (form) => {
  try {
    const request = await fetch(`${requestsURL}/uploadImageCatalogSection`, {
      method: "POST",
      body: form,
    });
    const data = await request.json();
    if (!request.ok) {
      throw new Error(
        "Error uploading content of the catalog section",
        data.message
      );
    }
    return data;
  } catch (error) {
    console.error("Error al subir el archivo", error);
  }
};

const getMainImageCatalog = async () => {
  try {
    const request = await fetch(`${requestsURL}/getImageCatalogSection`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await request.json();
    if (!request.ok) {
      throw new Error("Error getting catalog main image");
    }
    return data;
  } catch (error) {
    console.error("Unknown error getting image", error);
  }
};

const deleteMainImageCatalogSection = async (id) => {
  try {
    const request = await fetch(
      `${requestsURL}/deleteImageCatalogSection/${id}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await request.json();

    if (!request.ok) {
      throw new Error("Error deleting catalog main image");
    }
    return data;
  } catch (error) {
    console.error("Unknown error deleting image", error);
  }
};

const deleteCatalog = async (id) => {
  const request = await fetch(`${requestsURL}/delete/${id}`, {
    method: "DELETE",
    credentials: "include",
  });

  const deleteRequest = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Request", deleteRequest.message);
  }
  return deleteRequest;
};

export {
  getAllCatalogs,
  createCatalog,
  updateCatalog,
  deleteCatalog,
  uploadSectionImageCatalog,
  getMainImageCatalog,
  deleteMainImageCatalogSection,
};

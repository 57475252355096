//import { BASE_URL } from "./constants"

const BASE_URL = process.env.REACT_APP_BASE_URL;
const adsURL = `${BASE_URL}/ads`;

const getAllAds = async () => {
  // const filterParams = new URLSearchParams(filters)
  // const urlWithFilters = !!filterParams ? `${adsURL}&${filterParams.toString()}` : adsURL;
  // const newUrl = new URL(urlWithFilters)

  const request = await fetch(adsURL, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const allAds = await request.json();
  if (!request.ok) {
    throw new Error("Error on fetch", allAds.message);
  }
  return allAds;
};

const getAdsByFilter = async (filters) => {
  // Preparar los filtros para la URL
  const { adStatus, gvOperationClose, adBuildingType, adType, zone, page } =
    filters;

  const params = new URLSearchParams({
    ...filters,
    adStatus: JSON.stringify(adStatus),
    gvOperationClose: JSON.stringify(gvOperationClose),
    adBuildingType: JSON.stringify(adBuildingType),
    adType: JSON.stringify(adType),
    zone: JSON.stringify(zone),
    page: page,
  }).toString();

  const urlWithFilters = `${adsURL}/filter?${params}`;

  try {
    const response = await fetch(urlWithFilters, {
      method: "GET",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const adsFilter = await response.json();
    return adsFilter;
  } catch (error) {
    console.error("Error fetching ads by filter:", error);
    throw error; // Rethrow para manejar el error donde se llama a esta función
  }
};

const getAdById = async (id) => {
  const request = await fetch(`${adsURL}/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const adById = await request.json();
  if (!request.ok) {
    throw new Error("Error on fetch", adById.message);
  }
  return adById;
};

const getAllAdsByConsultantId = async (consultantId) => {
  const request = await fetch(`${adsURL}/consultant/${consultantId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const adsByConsultantId = await request.json();
  /* console.log(adsByConsultantId) */
  if (!request.ok) {
    throw new Error("Error on fetch", adsByConsultantId.message);
  }
  return adsByConsultantId;
};

const getMatchedRequests = async (id) => {
  const request = await fetch(`${adsURL}/matching/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const allRequests = await request.json();
  if (!request.ok) {
    throw new Error("Error on fetch", allRequests.message);
  }
  return allRequests;
};

const createAd = async (form) => {
  const request = await fetch(`${adsURL}/create`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const newAd = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Contact", newAd.message);
  }
  return newAd;
};

const updateAd = async (form) => {
  /* console.log(form) */
  const request = await fetch(`${adsURL}/edit`, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const updatedAd = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Contact", updatedAd.message);
  }
  return updatedAd;
};

const updateAdSendedTo = async (form) => {
  /* console.log(form) */
  const request = await fetch(`${adsURL}/edit/sendedto`, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const updatedAd = await request.json();
  if (!request.ok) {
    throw new Error("Error creating new Contact", updatedAd.message);
  }
  /* console.log(updatedAd) */
  return updatedAd;
};

const updateManyConsultantsAds = async (ads, currentConsultantId) => {
  /* console.log(form) */
  const request = await fetch(
    `${adsURL}/editmanyconsultant/${currentConsultantId}`,
    {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(ads),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const updatedAds = await request.json();

  if (!request.ok) {
    throw new Error("Error updating ads", updatedAds.message);
  }
  return updatedAds;
};

const uploadImage = async (id, form, from) => {
  const request = await fetch(`${adsURL}/upload/${from}/${id}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const uploadedMain = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Contact", uploadedMain.message);
  }
  return uploadedMain;
};

const deleteImage = async (id, form, from) => {
  const request = await fetch(`${adsURL}/delete/${from}/${id}`, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const deletedImage = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Contact", deletedImage.message);
  }
  return deletedImage;
};

const deleteAd = async (id) => {
  const request = await fetch(`${adsURL}/delete/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const deletedAd = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Contact", deletedAd.message);
  }
  return deletedAd;
};

export {
  getAllAds,
  getAdsByFilter,
  getAdById,
  getAllAdsByConsultantId,
  createAd,
  updateAd,
  updateAdSendedTo,
  updateManyConsultantsAds,
  uploadImage,
  deleteImage,
  getMatchedRequests,
  deleteAd,
};

import { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";

import {
  getAllPatrimonialZones,
  getAllResidentialZones,
  getAllOthersZones,
} from "../../api/zones.api";
import { deleteConsultantImage } from "../../api/consultants.api";

const typeMap = {
  residencial: "residential",
  patrimonial: "patrimonial",
  otros: "others",
};

export default function EmailSign({
  onChange,
  selectedZones,
  backgroundImages,
  setBackgroundImages,
  consultantId,
}) {
  const [zones, setZones] = useState({
    residential: [],
    patrimonial: [],
    others: [],
  });

  const [internalSelectedZones, setInternalSelectedZones] =
    useState(selectedZones);

  const [previewImages, setPreviewImages] = useState({
    high: { residential: "", patrimonial: "", others: "" },
    medium: { residential: "", patrimonial: "", others: "" },
    low: { residential: "", patrimonial: "", others: "" },
  });

  useEffect(() => {
    const fetchZones = async () => {
      const residentialZones = await getAllResidentialZones();
      const patrimonialZones = await getAllPatrimonialZones();
      const othersZones = await getAllOthersZones();

      const allZones = {
        residential: residentialZones,
        patrimonial: patrimonialZones,
        others: othersZones,
      };

      setZones(allZones);
      syncSelectedZones(selectedZones, allZones);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    syncSelectedZones(selectedZones, zones);
  }, [selectedZones, zones]);

  const syncSelectedZones = (selectedZones, allZones) => {
    const updatedSelectedZones = { high: {}, medium: {}, low: {} };

    ["high", "medium", "low"].forEach((priority) => {
      ["residential", "patrimonial", "others"].forEach((type) => {
        updatedSelectedZones[priority][type] = matchZones(
          selectedZones[priority][type],
          allZones[type]
        );
      });
    });

    setInternalSelectedZones(updatedSelectedZones);
  };

  const matchZones = (selected, all) => {
    return selected.map(
      (sel) => all.find((zone) => zone._id === sel._id) || sel
    );
  };

  const handleChange = (priority, type, value) => {
    const totalSelected =
      value.length +
      internalSelectedZones[priority].residential.length +
      internalSelectedZones[priority].patrimonial.length +
      internalSelectedZones[priority].others.length -
      internalSelectedZones[priority][typeMap[type]].length;

    if (totalSelected > 3) {
      return;
    }
    const updatedZones = {
      ...internalSelectedZones,
      [priority]: {
        ...internalSelectedZones[priority],
        [typeMap[type]]: value,
      },
    };

    // Eliminar la imagen si no hay zonas seleccionadas
    if (value.length === 0) {
      const updatedPreviewImages = {
        ...previewImages,
        [priority]: {
          ...previewImages[priority],
          [typeMap[type]]: "",
        },
      };
      setPreviewImages(updatedPreviewImages);

      const updatedBackgroundImages = {
        ...backgroundImages,
        [priority]: {
          ...backgroundImages[priority],
          [typeMap[type]]: "",
        },
      };
      setBackgroundImages(updatedBackgroundImages);

      deleteConsultantImage(
        consultantId,
        { toDelete: backgroundImages[priority][typeMap[type]] },
        `${priority}_${typeMap[type]}_backgroundImage`
      ).then((res) => {
        console.log(res.message);
      });
    }

    setInternalSelectedZones(updatedZones);
    onChange(updatedZones);
  };

  const getAvailableOptions = (priority, type) => {
    return zones[typeMap[type]].map((zone) => ({
      ...zone,
      disabled:
        !internalSelectedZones[priority][typeMap[type]].some(
          (z) => z._id === zone._id
        ) &&
        internalSelectedZones[priority].residential.length +
          internalSelectedZones[priority].patrimonial.length +
          internalSelectedZones[priority].others.length >=
          3,
    }));
  };

  const handleImageUpload = (priority, type, e) => {
    const file = e.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const newImage = event.target.result;

      const updatedPreviewImages = {
        ...previewImages,
        [priority]: {
          ...previewImages[priority],
          [typeMap[type]]: newImage,
        },
      };
      setPreviewImages(updatedPreviewImages);

      const updatedBackgroundImages = {
        ...backgroundImages,
        [priority]: {
          ...backgroundImages[priority],
          [typeMap[type]]: newImage,
        },
      };
      setBackgroundImages(updatedBackgroundImages);

      const updatedSelectedZones = { ...internalSelectedZones };
      const currentZone = updatedSelectedZones[priority][typeMap[type]];
      if (currentZone.length > 0) {
        updatedSelectedZones[priority][typeMap[type]] = currentZone.map(
          (zone, index) => {
            if (index === 0) {
              return { ...zone, image: newImage };
            }
            return zone;
          }
        );
      } else {
        updatedSelectedZones[priority][typeMap[type]] = [{ image: newImage }];
      }

      setInternalSelectedZones(updatedSelectedZones);
      onChange(updatedSelectedZones);
    };
    reader.readAsDataURL(file);
  };

  const handleDeletePreviewImage = (priority, type) => {
    const updatedPreviewImages = {
      ...previewImages,
      [priority]: {
        ...previewImages[priority],
        [typeMap[type]]: "",
      },
    };
    setPreviewImages(updatedPreviewImages);

    const updatedBackgroundImages = {
      ...backgroundImages,
      [priority]: {
        ...backgroundImages[priority],
        [typeMap[type]]: "",
      },
    };
    setBackgroundImages(updatedBackgroundImages);

    const updatedSelectedZones = { ...internalSelectedZones };

    if (updatedSelectedZones[priority][typeMap[type]].length > 0) {
      updatedSelectedZones[priority][typeMap[type]] = updatedSelectedZones[
        priority
      ][typeMap[type]].map((zone, index) => {
        if (index === 0) {
          return { ...zone, image: "" };
        }
        return zone;
      });
    }

    setInternalSelectedZones(updatedSelectedZones);
    onChange(updatedSelectedZones);
  };

  const handleDeleteImage = (priority, type) => {
    const imageUrl = backgroundImages[priority][typeMap[type]];
    const imageType = `${priority}_${typeMap[type]}_backgroundImage`;

    deleteConsultantImage(consultantId, { toDelete: imageUrl }, imageType).then(
      (res) => {
        const updatedBackgroundImages = {
          ...backgroundImages,
          [priority]: {
            ...backgroundImages[priority],
            [typeMap[type]]: "",
          },
        };
        setBackgroundImages(updatedBackgroundImages);
        const updatedSelectedZones = { ...internalSelectedZones };

        if (updatedSelectedZones[priority][typeMap[type]].length > 0) {
          updatedSelectedZones[priority][typeMap[type]] = updatedSelectedZones[
            priority
          ][typeMap[type]].map((zone, index) => {
            if (index === 0) {
              return { ...zone, image: "" };
            }
            return zone;
          });
        }

        setInternalSelectedZones(updatedSelectedZones);
        onChange(updatedSelectedZones);
        alert(`Imagen borrada exitosamente.`);
      }
    );
  };

  const hasSelectedZones = (priority, type) => {
    return internalSelectedZones[priority][typeMap[type]].length > 0;
  };

  return (
    <section
      style={{
        width: "100%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-evenly",
        gap: "20px",
        flexWrap: "wrap",
      }}
    >
      {["high", "medium", "low"].map((priority) => (
        <div key={priority} style={{ marginBottom: "1em", width: "100%" }}>
          <h3 style={{ marginBottom: "2rem", textAlign: "left" }}>
            {priority === "high"
              ? "Primera fila"
              : priority === "medium"
              ? "Segunda fila"
              : "Tercera fila"}
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "20px",
            }}
          >
            {["residencial", "patrimonial", "otros"].map((type) => (
              <div
                key={type}
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <h4 style={{ marginBottom: "10px" }}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </h4>
                <MultiSelect
                  value={internalSelectedZones[priority][typeMap[type]]}
                  options={getAvailableOptions(priority, type)}
                  onChange={(e) => handleChange(priority, type, e.value)}
                  optionLabel="name"
                  placeholder={`Selecciona Zonas ${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  }`}
                  display="chip"
                  style={{ width: "100%" }}
                  maxSelectedLabels={3}
                  selectedItemsLabel={`{0}/3 seleccionados`}
                  filter
                />
                <div style={{ marginTop: "20px" }}>
                  <h4 style={{ marginBottom: "10px" }}>Imagen de fondo</h4>
                  {previewImages[priority][typeMap[type]] ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={previewImages[priority][typeMap[type]]}
                        alt={`Fondo ${priority} ${type}`}
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      />
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        className="large-icon-button"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "#2b363d",
                          border: "none",
                          width: "40px", // ajusta este valor según sea necesario
                          height: "40px", // ajusta este valor según sea necesario
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          color: "white",
                        }}
                        onClick={() => handleDeletePreviewImage(priority, type)}
                      />
                    </div>
                  ) : backgroundImages[priority][typeMap[type]] ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={backgroundImages[priority][typeMap[type]]}
                        alt={`Fondo ${priority} ${type}`}
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      />
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        className="large-icon-button"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "#2b363d",
                          border: "none",
                          width: "40px", // ajusta este valor según sea necesario
                          height: "40px", // ajusta este valor según sea necesario
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          color: "white",
                        }}
                        onClick={() => handleDeleteImage(priority, type)}
                      />
                    </div>
                  ) : (
                    hasSelectedZones(priority, type) && (
                      <FileUpload
                        name="background"
                        customUpload
                        uploadHandler={(e) =>
                          handleImageUpload(priority, type, e)
                        }
                        accept="image/*"
                        maxFileSize={20971520}
                        auto
                        chooseLabel="Cargar imagen de fondo"
                      />
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
}

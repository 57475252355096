import * as React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { BiUser } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./Select.scss";

const CheckBoxGrouping = ({
  list,
  fields,
  fn,
  filter,
  disabled,
  defaultValues,
  label,
  defaultName,
}) => {
  const data = list;
  /* console.log(data); */
  // console.log(defaultValues)
  // console.log(defaultName)
  // const contactName = data.filter(contact => contact._id === defaultValues);
  // console.log(contactName)
  const checkFields = fields;
  // set the placeholder to the MultiSelect input.
  //Incluyo un ternario por si tengo datos de contacto en la url
  const checkWaterMark = defaultName !== "" ? defaultName : "Seleccionar";
  // // set mode value to the multiselect input
  // set the placeholder to the filter bar
  const filterBarPlaceholder = "Buscar";

  return (
    <div className="Select">
      <div>
        <label>{label}</label>
        {label === "Propietario" ? (
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`/contactos/${defaultValues}`}
          >
            <BiUser style={{ marginLeft: "5px", fontSize: "1.5rem" }} />
          </Link>
        ) : null}
        {label === "Consultor" ? (
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`/consultores/${defaultValues}`}
          >
            <BiUser style={{ marginLeft: "5px", fontSize: "1.5rem" }} />
          </Link>
        ) : null}

        {label === "Contacto" ? (
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`/contactos/${defaultValues}`}
          >
            <BiUser style={{ marginLeft: "5px", fontSize: "1.5rem" }} />
          </Link>
        ) : null}
      </div>
      <div id="multisection" className="control-panel">
        <div className="control-section">
          <div id="multigroup" className="control-styles Select__input">
            <DropDownListComponent
              id="boxelement"
              dataSource={data}
              ignoreAccent={true}
              ignoreCase={true}
              allowFiltering={filter ? true : false}
              filtering={filter}
              filterBarPlaceholder={filterBarPlaceholder}
              fields={checkFields}
              placeholder={checkWaterMark}
              required={true}
              value={defaultValues}
              showDropDownIcon={true}
              disabled={disabled}
              onChange={fn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxGrouping;

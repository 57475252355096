import React from "react";
import "./Input.scss";

const Input = ({
  label,
  type,
  placeholder,
  required,
  name,
  onBlur,
  value,
  onChange,
  onFocus,
  autoComplete,
  error,
  readOnly,
}) => {
  return (
    <div className="Input">
      <label>{label}</label>

      <div className={`Input-input ${error && "Input-error"}`}>
        <input
          type={type}
          name={name}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          readOnly={readOnly}
          min="0"
          onWheel={(e) => e.target.blur()}
          onKeyUp={(e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();
          }}
          onKeyDown={(e) => {
           if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();
          }}
        />
      </div>

      <p className="Input-errors">
        <small>
          <i>{error}</i>
        </small>
      </p>
    </div>
  );
};

export default Input;

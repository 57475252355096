//import { BASE_URL } from "./constants"
const BASE_URL = process.env.REACT_APP_BASE_URL;
const requestsURL = `${BASE_URL}/web`;

const getAllWebData = async () => {
  const request = await fetch(`${requestsURL}/home`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const allWebData = await request.json();
  if (!request.ok) {
    throw new Error("Error on fetch", allWebData.message);
  }
  return allWebData;
};

const updateCategoryHomeImage = async (form, imageName, catalodId) => {
  const request = await fetch(
    `${requestsURL}/home/categories/${imageName}/edit/${catalodId}`,
    {
      method: "PUT",
      credentials: "include",
      body: form,
    }
  );

  const editCatalog = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Catalog", editCatalog.message);
  }
  return editCatalog;
};

const updateHomeTextAndImage = async (form, catalodId) => {
  const request = await fetch(`${requestsURL}/home/edit/${catalodId}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const editCatalog = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Catalog", editCatalog.message);
  }
  return editCatalog;
};

const updateHomeServicesTextAndImage = async (form, imageName, dataId) => {
  const request = await fetch(
    `${requestsURL}/home/${imageName}/edit/${dataId}`,
    {
      method: "PUT",
      credentials: "include",
      body: form,
    }
  );
  const editService = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Service", editService.message);
  }
  return editService;
};

const updateContactHome = async (form, dataId) => {
  const request = await fetch(`${requestsURL}/home/talkwhitus/edit/${dataId}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const editContactHome = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Request", editContactHome.message);
  }
  return editContactHome;
};

const deleteCatalog = async (id) => {
  const request = await fetch(`${requestsURL}/delete/${id}`, {
    method: "DELETE",
    credentials: "include",
  });

  const deleteRequest = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Request", deleteRequest.message);
  }
  return deleteRequest;
};

const updateServicesTextAndImage = async (form, imageName, dataId) => {
  let urlService;
  if (imageName === "interiorimsImage") urlService = "interiorism";
  else if (imageName === "developmentImage") urlService = "development";
  else if (imageName === "investMentImage") urlService = "investment";
  else if (imageName === "assetManagementImage") urlService = "assetManagement";

  const request = await fetch(
    `${requestsURL}/services/${urlService}/edit/${dataId}`,
    {
      method: "PUT",
      credentials: "include",
      body: form,
    }
  );
  const editService = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Service", editService.message);
  }
  return editService;
};

const updateServices = async (formData, dataId, imageName) => {
  let urlService;
  if (imageName === "investMentImage") {
    urlService = "investment";
  } else if (imageName === "assetManagementImage") {
    urlService = "assetManagement";
  } else {
    urlService = "commercialization";
  }
  try {
    const response = await fetch(
      `${requestsURL}/services/${urlService}/edit/${dataId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Error editting Service", data.message);
    }
    return data;
  } catch (error) {
    console.error("Error al enviar formulario:", error);
  }
};

export {
  getAllWebData,
  updateCategoryHomeImage,
  updateHomeTextAndImage,
  updateHomeServicesTextAndImage,
  updateContactHome,
  updateServicesTextAndImage,
  updateServices,
};

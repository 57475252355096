import { FaTrash } from "react-icons/fa";
import { deleteMainImageCatalogSection } from "../../api/catalogs.api";

export default function CatalogMainImage({ image, setMainImageCatalog }) {
  const removeMainImageCatalogSection = async (id) => {
    const response = await deleteMainImageCatalogSection(id);
    if (response.message === "Deleted Susscesfully") setMainImageCatalog([]);
  };

  return (
    <div className="CatalogCard">
      <img
        className="CatalogCard__image"
        src={image[0].imgSection}
        alt={`Imagen principal de la página de catálogo `}
      />
      <div className="CatalogCard__buttons">
        <FaTrash
          className="cursor"
          style={{
            transform: "scale(125%)",
            verticalAlign: "middle",
            color: "#2B363D",
            marginTop: "15px",
          }}
          onClick={() => removeMainImageCatalogSection(image[0]._id)}
        />
      </div>
    </div>
  );
}

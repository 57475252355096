import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getAllRequests, getRequestsByFilter } from "../../api/requests.api";
import RequestsTable from "../../components/RequestsTable/RequestsTable";
import Layout from "../Layout/Layout";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import { UserContext } from "../../components/Context/AuthUser";
import { checkSession } from "../../api/auth.api";
import { CustomRequestsFilters } from "../../components/Context/RequestsFilters";

const RequestsList = () => {
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState(-1);

  const { user } = useContext(UserContext);
  const customRequestFilters = useContext(CustomRequestsFilters);
  const filterPetitionSetter = customRequestFilters.setFilters;

  // Memoriza los filtros para evitar recalculos innecesarios
  const requestsFilters = useMemo(
    () => customRequestFilters.requestsFilters,
    [customRequestFilters.requestsFilters]
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (user.length === 0) {
      navigate("/");
    }
  }, [user, navigate]);

  const paginate = useCallback(
    (pageNumber) => {
      filterPetitionSetter({
        ...requestsFilters,
        page: pageNumber,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [requestsFilters]
  );

  const RequestsListFooter = useCallback(
    () => (
      <Pagination
        currentPage={requestsFilters.page}
        totalPages={totalPages}
        paginate={paginate}
      />
    ),
    [requestsFilters.page, totalPages, paginate]
  );

  useEffect(() => {
    const fetchAds = async () => {
      setLoader(true);
      const data = await getRequestsByFilter({
        ...requestsFilters,
        sortField,
        sortOrder: sortOrder === 1 ? "ASC" : "DESC",
      });
      if (data) {
        setRequests(data.requests);
        setTotalPages(data.pageInfo.totalPages);
        setTotalElements(data.pageInfo.totalElements);
        setLoader(false);
        filterPetitionSetter({
          ...requestsFilters,
          page: data.pageInfo.page,
        });
      }
    };

    fetchAds();
  }, [requestsFilters.page]);

  return (
    <Layout
      subTitle="Peticiones"
      subList={totalElements}
      subLocation={() => navigate("/peticiones/crear")}
      subSetter={setRequests}
      setTotalPages={setTotalPages}
      setTotalElements={setTotalElements}
      footContent={<RequestsListFooter />}
    >
      {loader ? (
        <Spinner />
      ) : (
        <RequestsTable
          requests={requests}
          setRequests={setRequests}
          currentPage={requestsFilters.page}
          setTotalPages={setTotalPages}
          setTotalElements={setTotalElements}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
        />
      )}
    </Layout>
  );
};

export default RequestsList;

import {
  getAllResidentialZones,
  getAllPatrimonialZones,
  getAllOthersZones,
} from "../api/zones.api";

export const adStatusOptions = [
  { name: "En preparación" },
  { name: "Activo" },
  { name: "Inactivo" },
];
export const gvOperationCloseOptions = [
  { name: "Alquilado" },
  { name: "Vendido" },
];
export const adTypeOptions = [{ name: "Alquiler" }, { name: "Venta" }];
export const adDepartmentOptions = [
  { name: "Patrimonio" },
  { name: "Residencial" },
  { name: "Otros" },
];
export const adBuildingTypeOptions = [
  { name: "Casa" },
  { name: "Piso" },
  { name: "Parcela" },
  { name: "Ático" },
  { name: "Oficina" },
  { name: "Edificio" },
  { name: "Local" },
  // { name: "Edificio exclusivo de oficinas" },
  // { name: "Edificio clásico" },
  // { name: "Coworking" },
  { name: "Campo Rústico" },
  { name: "Activos singulares" },
  { name: "Costa" },
];
export const adSaleOrderOptions = [
  { name: "Defecto" },
  { name: "Descendente" },
  { name: "Ascendente" },
];

export const asRentOrderOptions = [
  { name: "Defecto" },
  { name: "Descendente" },
  { name: "Ascendente" },
];

export const residentialZones = getAllResidentialZones();
export const patrimonialZones = getAllPatrimonialZones();
export const othersZones = getAllOthersZones();

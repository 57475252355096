//import { BASE_URL } from "./constants"
const BASE_URL = process.env.REACT_APP_BASE_URL;
const requestsURL = `${BASE_URL}/marketingCampaigns`;

const getAllMarketingCampaigns = async () => {
  const request = await fetch(`${requestsURL}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const marketingsCampaigns = await request.json();
  if (!request.ok) {
    throw new Error("Error on fetch", marketingsCampaigns.message);
  }
  return marketingsCampaigns;
};

const createMarketingCampaign = async (form) => {
  const request = await fetch(`${requestsURL}/create`, {
    method: "POST",
    credentials: "include",
    body: form,
  });

  const newMarketingCampaign = await request.json();

  if (!request.ok) {
    throw new Error(
      "Error creating new Marketing campaign",
      newMarketingCampaign.message
    );
  }
  return newMarketingCampaign;
};

const updateMarketingCampaign = async (form, catalodId) => {
  const request = await fetch(`${requestsURL}/edit/${catalodId}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const editMarketingCampaign = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Catalog", editMarketingCampaign.message);
  }
  return editMarketingCampaign;
};

const sendMarketingCampaign = async (data) => {
  const request = await fetch(`${requestsURL}/sendEmail`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const sendMarketingCampaign = await request.json();

  if (!request.ok) {
    throw new Error(
      "Error send Marketing Campaign ",
      sendMarketingCampaign.message
    );
  }
  return sendMarketingCampaign;
};

const updateCategoryHomeImage = async (form, imageName, catalodId) => {
  const request = await fetch(
    `${requestsURL}/home/categories/${imageName}/edit/${catalodId}`,
    {
      method: "PUT",
      credentials: "include",
      body: form,
    }
  );

  const editCatalog = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Catalog", editCatalog.message);
  }
  return editCatalog;
};

const updateHomeTextAndImage = async (form, catalodId) => {
  const request = await fetch(`${requestsURL}/home/edit/${catalodId}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const editCatalog = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Catalog", editCatalog.message);
  }
  return editCatalog;
};

const updateHomeServicesTextAndImage = async (form, imageName, dataId) => {
  const request = await fetch(
    `${requestsURL}/home/${imageName}/edit/${dataId}`,
    {
      method: "PUT",
      credentials: "include",
      body: form,
    }
  );
  const editService = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Service", editService.message);
  }
  return editService;
};

const updateContactHome = async (form, dataId) => {
  const request = await fetch(`${requestsURL}/home/talkwhitus/edit/${dataId}`, {
    method: "PUT",
    credentials: "include",
    body: form,
  });

  const editContactHome = await request.json();

  if (!request.ok) {
    throw new Error("Error creating new Request", editContactHome.message);
  }
  return editContactHome;
};

const deleteMarketingCampaign = async (id, campaignImage) => {
  const request = await fetch(`${requestsURL}/delete/${id}`, {
    method: "DELETE",
    credentials: "include",
    body: JSON.stringify(campaignImage),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const deleteMarketingCampaign = await request.json();

  if (!request.ok) {
    throw new Error(
      "Error creating new Request",
      deleteMarketingCampaign.message
    );
  }
  return deleteMarketingCampaign;
};

const updateServicesTextAndImage = async (form, imageName, dataId) => {
  let urlService;
  if (imageName === "interiorimsImage") urlService = "interiorism";
  else if (imageName === "developmentImage") urlService = "development";
  const request = await fetch(
    `${requestsURL}/services/${urlService}/edit/${dataId}`,
    {
      method: "PUT",
      credentials: "include",
      body: form,
    }
  );
  const editService = await request.json();

  if (!request.ok) {
    throw new Error("Error editting Service", editService.message);
  }
  return editService;
};

export {
  getAllMarketingCampaigns,
  createMarketingCampaign,
  updateMarketingCampaign,
  deleteMarketingCampaign,
  sendMarketingCampaign,
};

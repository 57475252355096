import { useEffect, useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import {
  getAllWebData,
  updateServices,
  updateServicesTextAndImage,
} from "../../api/webData.api";
import "./ServicesEditForm.scss";

const ServicesEditForm2 = ({
  setOpenEditServicesForm,
  webData,
  setWebData,
  setHideContent,
  imageName,
  service,
}) => {
  const [title, setTitle] = useState(service.title);
  const [descriptionInvestment, setDescriptionInvestment] = useState(
    service.description
  );
  const [descriptionAssetAndCommer1, setDescriptionAssetAndCommer1] = useState(
    service.description1
  );
  const [descriptionAssetAndCommer2, setDescriptionAssetAndCommer2] = useState(
    service.description2
  );
  const [descriptionAssetAndCommer3, setDescriptionAssetAndCommer3] = useState(
    service.description3
  );
  const [descriptionAssetAndCommer4, setDescriptionAssetAndCommer4] = useState(
    service.description4
  );
  const [sectionInvestmentData, setSectionInvestmentData] = useState(
    service.investmentSections
  );
  const [sectionCommerData, setSectionCommerData] = useState(
    service.commerSections
  );
  const SubmitForm = async (e) => {
    e.preventDefault();

    const formData =
      imageName === "investMentImage"
        ? {
            title: e.target.ServiceTitle.value,
            description: e.target.ServiceDescription.value,
            investmentSections: sectionInvestmentData,
          }
        : imageName === "assetManagementImage"
        ? {
            title: e.target.ServiceTitle.value,
            description1: e.target.ServiceDescriptionAsset1.value,
            description2: e.target.ServiceDescriptionAsset2.value,
            description3: e.target.ServiceDescriptionAsset3.value,
            description4: e.target.ServiceDescriptionAsset4.value,
          }
        : {
            title: e.target.ServiceTitle.value,
            description1: e.target.ServiceDescriptionCommer1.value,
            description2: e.target.ServiceDescriptionCommer2.value,
            commerSections: sectionCommerData,
          };

    const uploadHomeServices = await updateServices(
      formData,
      webData._id,
      imageName
    );
    const { services } = uploadHomeServices;

    if (imageName === "investMentImage") {
      webData.services.investment.title = services.investment.title;
      webData.services.investment.investmentSections =
        services.investment.investmentSections;
      webData.services.investment.description = services.investment.description;
    } else if (imageName === "assetManagement") {
      webData.services.assetManagement.title = services.assetManagement.title;
      webData.services.assetManagement.description1 =
        services.assetManagement.description1;
      webData.services.assetManagement.description1 =
        services.assetManagement.description2;
      webData.services.assetManagement.description1 =
        services.assetManagement.description3;
      webData.services.assetManagement.description1 =
        services.assetManagement.description4;
    } else {
      webData.services.commercialization.title =
        services.commercialization.title;
      webData.services.commercialization.description1 =
        services.commercialization.description1;
      webData.services.commercialization.description2 =
        services.commercialization.description2;
      webData.services.commercialization.commerSections =
        services.commercialization.commerSections;
    }

    setOpenEditServicesForm(false);
    setHideContent(false);
  };

  const handleInputSectionInvestmentChange = (index, newValue) => {
    const newValues = [...sectionInvestmentData];
    newValues[index] = newValue;
    setSectionInvestmentData(newValues);
  };

  const handleInputSectionCommerChange = (index, newValue) => {
    const newValues = [...sectionCommerData];
    newValues[index] = newValue;
    setSectionCommerData(newValues);
  };

  return (
    <form onSubmit={SubmitForm} className="ServicesEditForm">
      <div className="ServicesEditForm__title">
        <h1>Editar Servicios</h1>
        <IoIosClose
          className="ServicesEditForm__title__close"
          onClick={() => {
            setOpenEditServicesForm(false);
            setHideContent(false);
          }}
        />
      </div>
      <div className="ServicesEditForm__item">
        <label htmlFor="ServiceTitle">Título</label>
        <input
          type="text"
          name="ServiceTitle"
          id="ServiceTitle"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="ServicesEditForm__item">
        {imageName === "investMentImage" ? (
          <>
            <label htmlFor="ServiceDescription">Descripción</label>
            <textarea
              name="ServiceDescription"
              id="ServiceDescription"
              rows="4"
              cols="50"
              value={descriptionInvestment}
              onChange={(e) => setDescriptionInvestment(e.target.value)}
            />
          </>
        ) : imageName === "assetManagementImage" ? (
          <div className="Services__box">
            <div className="Services__box__item">
              <label htmlFor="ServiceDescriptionAsset1">Descripción-1</label>
              <textarea
                name="ServiceDescriptionAsset1"
                id="ServiceDescriptionAsset1"
                rows="4"
                cols="50"
                value={descriptionAssetAndCommer1}
                onChange={(e) => setDescriptionAssetAndCommer1(e.target.value)}
              />
            </div>
            <div className="Services__box__item">
              <label htmlFor="ServiceDescriptionAsset2">Descripción-2</label>
              <textarea
                name="ServiceDescriptionAsset2"
                id="ServiceDescriptionAsset2"
                rows="4"
                cols="50"
                value={descriptionAssetAndCommer2}
                onChange={(e) => setDescriptionAssetAndCommer2(e.target.value)}
              />
            </div>
            <div className="Services__box__item">
              <label htmlFor="ServiceDescriptionAsset3">Descripción-3</label>
              <textarea
                name="ServiceDescriptionAsset3"
                id="ServiceDescriptionAsset3"
                rows="4"
                cols="50"
                value={descriptionAssetAndCommer3}
                onChange={(e) => setDescriptionAssetAndCommer3(e.target.value)}
              />
            </div>
            <div className="Services__box__item">
              <label htmlFor="ServiceDescriptionAsset4">Descripción-4</label>
              <textarea
                name="ServiceDescriptionAsset4"
                id="ServiceDescriptionAsset4"
                rows="4"
                cols="50"
                value={descriptionAssetAndCommer4}
                onChange={(e) => setDescriptionAssetAndCommer4(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div className="Services__box">
            <div className="Services__box__item">
              <label htmlFor="ServiceDescriptionCommer1">Descripción-1</label>
              <textarea
                name="ServiceDescriptionCommer1"
                id="ServiceDescriptionCommer1"
                rows="4"
                cols="50"
                value={descriptionAssetAndCommer1}
                onChange={(e) => setDescriptionAssetAndCommer1(e.target.value)}
              />
            </div>
            <div className="Services__box__item">
              <label htmlFor="ServiceDescriptionCommer2">Descripción-2</label>
              <textarea
                name="ServiceDescriptionCommer2"
                id="ServiceDescriptionCommer2"
                rows="4"
                cols="50"
                value={descriptionAssetAndCommer2}
                onChange={(e) => setDescriptionAssetAndCommer2(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="Services__box">
        {imageName === "investMentImage"
          ? sectionInvestmentData?.map((item, i) => (
              <div className="Services__box__item" key={i}>
                <label htmlFor={`ServiceTitle${i}`}>Texto-{i + 1}</label>
                <input
                  type="text"
                  name={`ServiceTitle${i}`}
                  id={`ServiceTitle${i}`}
                  value={item}
                  onChange={(e) =>
                    handleInputSectionInvestmentChange(i, e.target.value)
                  }
                />
              </div>
            ))
          : sectionCommerData?.map((item, i) => (
              <div className="Services__box__item" key={i}>
                <label htmlFor={`ServiceTitle${i}`}>Texto-{i + 1}</label>
                <input
                  type="text"
                  name={`ServiceTitle${i}`}
                  id={`ServiceTitle${i}`}
                  value={item}
                  onChange={(e) =>
                    handleInputSectionCommerChange(i, e.target.value)
                  }
                />
              </div>
            ))}
      </div>
      <div className="ServicesEditForm__buttons">
        <button
          type="button"
          className="subHeader__btn"
          onClick={() => {
            setOpenEditServicesForm(false);
            setHideContent(false);
          }}
        >
          Cancelar
        </button>
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
      </div>
    </form>
  );
};

export default ServicesEditForm2;

import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./MarketingCampaignsSend.scss";
import { Navbar, Select } from "../../components";
import useViewport from "../../hooks/useViewport";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MarketingCampaignsNavbar from "../../components/MarketingCampaignsNavbar/MarketingCampaignsNavbar";
import { getContactsByEmailNotifications } from "../../api/contacts.api";
import {
  getAllMarketingCampaigns,
  sendMarketingCampaign,
} from "../../api/marketingCampaigns.api";
import { GvreLogo } from "../../icons/index";
import PopUp from "../../components/PopUp/PopUp";
import { UserContext } from "../../components/Context/AuthUser";
// import { checkSession } from "../../api/auth.api";
import { InputText } from "primereact/inputtext";
import useWindowSize from "../../hooks/useWindowSize";

const MarketingCampaignsSend = ({ children, subTitle }) => {
  useViewport();
  // const navigate = useNavigate();

  const [marketingsCampaigns, setMarketingsCampaigns] = useState([]);
  const [selectedMarketingCampaign, setSelectedMarketingCampaign] = useState(
    []
  );
  const [contacts, setContacts] = useState([]);
  const [filterContactsList, setFilterContactList] = useState([]);
  const [contactsToSend, setContactsToSend] = useState([]);
  const [openSendMarketingCampaignEmail, setOpenSendMarketingCampaignEmail] =
    useState(false);

  const { user, deleteUser } = useContext(UserContext);
  const size = useWindowSize();

  async function fetchGetContactsByEmailNotifications() {
    const contactsWithEmailNotification =
      await getContactsByEmailNotifications();
    setContacts(contactsWithEmailNotification);
    setFilterContactList(contactsWithEmailNotification);
  }

  async function fetchgetAllMarketingCampaigns() {
    const marketingsCampaigns = await getAllMarketingCampaigns();
    setMarketingsCampaigns(marketingsCampaigns);
  }

  // useEffect(() => {
  //   checkSession().then((res) => {
  //     if (res === "Acceso restringido") {
  //       deleteUser();
  //       navigate("/");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    fetchGetContactsByEmailNotifications();
    fetchgetAllMarketingCampaigns();
  }, []);

  // useEffect(() => {
  //   console.log(contactsToSend);
  //   console.log(selectedMarketingCampaign);
  // }, [contactsToSend, selectedMarketingCampaign]);

  const checkIfIncludes = (origin, text) => {
    return origin
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
  };

  const handleOpenSendMarketingCampaignEmail = () => {
    setOpenSendMarketingCampaignEmail(!openSendMarketingCampaignEmail);
  };

  const customFilter = (text) => {
    const contactFiltered = contacts.filter((contact) => {
      if (
        checkIfIncludes(contact.fullName, text) ||
        checkIfIncludes(contact.email, text) ||
        checkIfIncludes(contact.company, text) ||
        checkIfIncludes(contact.contactMobileNumber, text)
      )
        return contact;
    });
    setFilterContactList(contactFiltered);
  };

  return (
    <div className="LayoutMarketingCampaigns">
      <Navbar title={subTitle} />
      <div className="LayoutMarketingCampaigns__contain">
        <MarketingCampaignsNavbar />

        <div className="LayoutMarketingCampaigns__contain__children">
          {/* {children} */}
          <h1>Envíos</h1>
          <>
            <button
              tpye="button"
              className={
                contactsToSend.length !== 0 &&
                selectedMarketingCampaign.length !== 0
                  ? "subHeader__btn LayoutMarketingCampaigns__button"
                  : "subHeader__btn LayoutMarketingCampaigns__button buttonForm--disabled"
              }
              onClick={() => {
                if (contactsToSend.length !== 0)
                  handleOpenSendMarketingCampaignEmail();
              }}
              disabled={contactsToSend.length !== 0 ? false : true}
            >
              Enviar
            </button>
          </>
          {/* {openSendMarketingCampaignEmail && (
            <MarketingCampaignEmail
              setOpenSendMarketingCampaignEmail={setOpenSendMarketingCampaignEmail}
              selectedMarketingCampaign={selectedMarketingCampaign}
              contacts={contacts}
            />
          )} */}
          <div className="LayoutMarketingCampaigns__contain__children__campaignSelect">
            <Select
              label="Campañas de marketing"
              list={marketingsCampaigns?.sort((a, b) => {
                if (a.createdAt < b.createdAt) {
                  return 1;
                } else return -1;
              })}
              fields={{ groupBy: "", text: "title", value: "_id" }}
              filter={(e) => {
                const searchData = marketingsCampaigns.filter((campaign) => {
                  if (
                    checkIfIncludes(campaign.title, e.text) ||
                    checkIfIncludes(campaign.description, e.text)
                  )
                    return campaign;
                });
                if (searchData.length !== 0) e.updateData(searchData);
                else e.updateData([]);
              }}
              fn={(ev) => {
                const selectedCampaign = marketingsCampaigns.filter(
                  (campaig) => campaig._id === ev.target.value
                );
                setSelectedMarketingCampaign(selectedCampaign);
              }}
              defaultName={"Seleccione una campaña"}
            />
            <div
              className="LayoutMarketingCampaigns__contain__children__campaignSelect__search"
              style={
                size < 425
                  ? {
                      display: "flex",
                      width: "90vw",
                      justifyContent: "space-between",
                    }
                  : { display: "flex" }
              }
            >
              <div className="p-input-icon-left">
                {/* <i className="pi pi-search" /> */}
                <InputText
                  id="search"
                  onChange={(ev) => {
                    customFilter(ev.target.value);
                  }}
                  placeholder="Buscar en esta lista"
                  style={
                    size > 425
                      ? { width: "30rem", marginRight: "1vw" }
                      : { width: "20rem" }
                  }
                />
              </div>
            </div>
          </div>
          <div className="LayoutMarketingCampaigns__contain__children__contactsTable">
            <p className="LayoutMarketingCampaigns__contain__children__contactsTable__title">
              Has seleccionado {contactsToSend.length} contactos.
            </p>
            <DataTable
              value={filterContactsList.length !== 0 ? filterContactsList : ""}
              paginator
              rows={10}
              removableSort
              responsiveLayout="scroll"
              resizableColumns
              dataKey="_id"
              selectionMode="checkbox"
              selection={contactsToSend}
              onSelectionChange={(ev) => {
                let contacts = [];
                contacts = ev.value.filter((contacts) => {
                  return contacts;
                });
                setContactsToSend(contacts);
              }}
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              rowsPerPageOptions={[10, 25, 50]}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "1%" }}
              ></Column>
              <Column
                field="fullName"
                header="Nombre"
                body={(ev) => (
                  <Link to={`/contactos/${ev._id}`}>{ev.fullName}</Link>
                )}
                sortable
                style={{ width: "5%" }}
              ></Column>
              <Column
                field="email"
                header="Email"
                style={{ width: "5%" }}
              ></Column>
              <Column
                field="company"
                header="Empresa"
                style={{ width: "5%" }}
              ></Column>
            </DataTable>
            <div>
              {openSendMarketingCampaignEmail && (
                <PopUp
                  handlePopUp={handleOpenSendMarketingCampaignEmail}
                  height="95%"
                  mobileHeight="85%"
                  width="60%"
                  title="Plantilla de email"
                  buttons={
                    <>
                      <button
                        className="buttonFormCancel"
                        onClick={handleOpenSendMarketingCampaignEmail}
                      >
                        Cancelar
                      </button>
                      <button
                        className="buttonForm"
                        onClick={async () => {
                          // console.log("contacto:", contactsToSend);
                          // console.log("consultor:", user);
                          // console.log(
                          //   "asunto:",
                          //   document.getElementById("subject").value
                          // );
                          // console.log(
                          //   "Mensaje1",
                          //   document.getElementById("mailMessage1").value
                          // );
                          // console.log(
                          //   "Mensaje2",
                          //   document.getElementById("mailMessage2").value
                          // );
                          // console.log(
                          //   "Mensaje3",
                          //   document.getElementById("mailMessage3").value
                          // );
                          // console.log(
                          //   "Mensaje4",
                          //   document.getElementById("mailMessage4").value
                          // );
                          // console.log(
                          //   "Mensaje5",
                          //   document.getElementById("mailMessage5").value
                          // );
                          // console.log("campaña:", selectedMarketingCampaign[0]);

                          const data = {
                            consultant: user,
                            contacts: contactsToSend,
                            subject: document.getElementById("subject").value,
                            messageP1:
                              document.getElementById("mailMessage1").value,
                            messageP2:
                              document.getElementById("mailMessage2").value,
                            messageP3:
                              document.getElementById("mailMessage3").value,
                            messageGoodbyeP1:
                              document.getElementById("mailMessage4").value,
                            messageGoodbyeP2:
                              document.getElementById("mailMessage5").value,
                            campaign: selectedMarketingCampaign[0],
                          };
                          // console.log("data:", data);
                          const sendCampaign = await sendMarketingCampaign(
                            data
                          );
                          alert(`Mensajes enviados`);
                          if (
                            Object.keys(sendCampaign).includes("editContact")
                          ) {
                            // console.log("Campañas enviadas");
                            handleOpenSendMarketingCampaignEmail();
                          }
                          // contactsToSend.map(async (contact) => {
                          //   // console.log(contact)
                          //   await sendAdToContacts({
                          // consultant: user,
                          //     contact,
                          //     subject: document.getElementById("subject").value,
                          //     messageP1:
                          //       document.getElementById("mailMessage1").value,
                          //     messageP2:
                          //       document.getElementById("mailMessage2").value,
                          //     messageP3:
                          //       document.getElementById("mailMessage3").value,
                          //     messageGoodbyeP1:
                          //       document.getElementById("mailMessage4").value,
                          //     messageGoodbyeP2:
                          //       document.getElementById("mailMessage5").value,
                          //     campaign: selectedMarketingCampaign[0],
                          //   });
                          /** */
                          /** */
                          /* These lines update the ad and add a new property in ad object. Comment this not to update the ads and modificate ad list */
                          /** */
                          /** */

                          //const today = new Date();
                          //adById.sendedTo.push({contactRequestName:request.requestContact.fullName, contactRequestEmail:request.requestContact.email, requestReferenceId:request.requestReference, sendedAt:today.toISOString()})
                          /* console.log(request) */
                          /* console.log(adById) */
                          //requestsToSend.forEach(async (req) => await updateAdSendedTo(adById))
                          // });
                          // alert(`Mensajes enviados`);
                          // handleOpenSendMarketingCampaignEmail();
                        }}
                      >
                        Enviar
                      </button>
                    </>
                  }
                  fixedButtons={true}
                >
                  {/* // ================================================================================ */}
                  <div className="EmailTemplate">
                    <div className="EmailTemplate__Header">
                      <GvreLogo
                        style={{
                          width: "6%",
                          color: "#FFF",
                          bottom: "50%",
                          left: "50%",
                          margin: 12,
                        }}
                      />
                    </div>
                    <div className="EmailTemplate__Body">
                      {/* INTRO */}
                      <div className="EmailTemplate__Body__Introduction">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5 style={{ marginRight: 12 }}>Asunto:</h5>
                          <textarea
                            id="subject"
                            // defaultValue={`Estimado/a ${requestsToSend[0].requestContact.fullName}, desde GV Real Estate te recomendamos el siguiente inmueble`}
                            defaultValue={`${selectedMarketingCampaign[0].title}`}
                            onChange={(ev) => {
                              console.log(ev.target.value);
                            }}
                          />
                        </div>

                        <div className="EmailTemplate__Body__Estates">
                          <div className="EmailTemplate__Body__Estates__Item">
                            {selectedMarketingCampaign[0].image ? (
                              <img
                                src={selectedMarketingCampaign[0].image}
                                alt="Imagen principal"
                                style={{ width: "76%", marginBottom: 20 }}
                              />
                            ) : (
                              <img
                                src="\defaultImage.png"
                                alt="Imagen por defecto"
                                style={{
                                  width: "75%",
                                  height: "100%",
                                  borderRadius: "4px",
                                  marginRight: 12,
                                  marginLeft: "10%",
                                  marginBottom: 20,
                                }}
                              />
                            )}
                            <div className="EmailTemplate__Body__Introduction">
                              <textarea
                                placeholder={
                                  "Inserte texto adicional si lo desea"
                                }
                                defaultValue={""}
                                onChange={(ev) =>
                                  (selectedMarketingCampaign[0].adComment =
                                    ev.target.value)
                                }
                                style={{ minHeight: "5%" }}
                              />
                            </div>
                            {/* <div className="EmailTemplate__Body__Title">
                            <textarea
                              style={{
                                display: "block",
                                fontSize: "1.17em",
                                marginTop: "1em",
                                marginBottom: "1em",
                                marginLeft: "0",
                                marginRight: "0",
                                fontWeight: "bold",
                                minWidth: "5%",
                              }}
                              defaultValue={selectedMarketingCampaign[0].title}
                              onChange={(ev) => {
                                selectedMarketingCampaign[0].titleEdited =
                                  ev.target.value;
                              }}
                            />
                          </div> */}

                            {/* <div className="EmailTemplate__Body__Estates__Item__Button">
                            <button
                            // onClick={() => history.push(`/anuncios/${ad._id}`)}
                            >
                              Saber más
                            </button>
                          </div> */}
                          </div>
                        </div>
                        <textarea
                          id="mailMessage1"
                          // defaultValue={`Buenos días ${requestsToSend[0].requestContact.fullName}`}
                          defaultValue={`Buenos días`}
                          onChange={(ev) => {
                            console.log(ev.target.value);
                          }}
                        />
                        <textarea
                          id="mailMessage2"
                          defaultValue={`Desde GV Real Estate te queremos enviar la información relativa a la campaña ${selectedMarketingCampaign[0].title}`}
                          onChange={(ev) => console.log(ev.target.value)}
                        />
                        <textarea
                          id="mailMessage3"
                          defaultValue={`${selectedMarketingCampaign[0].description}`}
                          onChange={(ev) => console.log(ev.target.value)}
                        />
                      </div>
                      {/* ESTATES */}

                      {/* CONCLUSION */}
                      <div className="EmailTemplate__Body__Introduction">
                        <textarea
                          id="mailMessage4"
                          defaultValue={`Que pase un feliz día.`}
                          onChange={(ev) => console.log(ev.target.value)}
                        />
                        <textarea
                          id="mailMessage5"
                          defaultValue={`Un cordial saludo,`}
                          onChange={(ev) => console.log(ev.target.value)}
                        />
                      </div>
                      {/* SIGNATURE */}
                      <div className="EmailTemplate__Body__Signature">
                        <GvreLogo
                          style={{
                            width: "8%",
                            color: "#2B363D",
                            bottom: "-52%",
                            left: "-78%",
                          }}
                        />
                        <div>
                          <p>{user.fullName}</p>
                          <p>
                            {user.profession
                              ? `${user.position} | ${user.profession}`
                              : `${user.position}`}
                          </p>
                          <p>
                            {user.consultantPhoneNumber
                              ? `${user.consultantMobileNumber} | ${user.consultantPhoneNumber}`
                              : `${user.consultantMobileNumber}`}
                          </p>
                          {user.office2
                            ? `${user.office1} | ${user.office2}`
                            : `${user.office1}`}
                          <p>{user.consultantEmail}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </PopUp>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingCampaignsSend;

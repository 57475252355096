import React, { useEffect, useState } from "react";
import "./MarketingCampaigns.scss";
import { Navbar } from "../../components";
import useViewport from "../../hooks/useViewport";
import MarketingCampaignsNavbar from "../../components/MarketingCampaignsNavbar/MarketingCampaignsNavbar";
import { getAllMarketingCampaigns } from "../../api/marketingCampaigns.api";
import CampaignsCard from "../../components/CampaignsCard/CampaignsCard";
import MarketingCampaignNewForm from "../../components/MarketingCampaignNewForm/MarketingCampaignNewForm";

const MarketingCampaigns = ({ children, subTitle }) => {
  useViewport();

  const [marketingsCampaigns, setMarketingsCampaigns] = useState([]);
  const [openNewMarketingCampaign, setOpenNewMarketingCampaign] =
    useState(false);

  async function fetchgetAllMarketingCampaigns() {
    const marketingsCampaigns = await getAllMarketingCampaigns();
    setMarketingsCampaigns(marketingsCampaigns);
  }

  useEffect(() => {
    fetchgetAllMarketingCampaigns();
  }, []);

  return (
    <div className="LayoutMarketingCampaigns">
      <Navbar title={subTitle} />
      <div className="LayoutMarketingCampaigns__contain">
        <MarketingCampaignsNavbar />

        <div className="LayoutMarketingCampaigns__contain__children">
          {/* {children} */}
          <h1>Campañas</h1>
          <>
            <button
              tpye="button"
              className="subHeader__btn LayoutMarketingCampaigns__button"
              onClick={() => setOpenNewMarketingCampaign(true)}
            >
              Nuevo
            </button>
          </>
          {openNewMarketingCampaign && (
            <MarketingCampaignNewForm
              setOpenNewMarketingCampaign={setOpenNewMarketingCampaign}
              marketingsCampaigns={marketingsCampaigns}
              setMarketingsCampaigns={setMarketingsCampaigns}
            />
          )}
          <div className="LayoutMarketingCampaigns__contain__children__campaigns">
            {marketingsCampaigns.length !== 0 &&
              marketingsCampaigns.map((campaign, i) => (
                <CampaignsCard
                  key={campaign.title}
                  campaign={campaign}
                  campaigns={marketingsCampaigns}
                  setCampaigns={setMarketingsCampaigns}
                  index={i}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingCampaigns;

import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import "./CatalogNewForm.scss";
import { createCatalog } from "../../api/catalogs.api";
const CatalogNewForm = ({ setOpenNewCatalogForm, catalogs, setCatalogs }) => {
  const [files, setFiles] = useState([]);
  const [formYearError, setFormYearError] = useState(false);
  const [formFilesError, setFormFilesError] = useState(false);
  const SubmitForm = async (e) => {
    e.preventDefault();
    setFormYearError(false);
    setFormFilesError(false);
    if (e.target.year.value === "") {
      setFormYearError(true);
    }
    if (files.length < 2) {
      setFormFilesError(true);
    }
    const fileData = new FormData();
    fileData.append("year", e.target.year.value);
    for (let file of files) {
      fileData.append("files", file);
    }
    // for (let item of fileData) {
    //   console.log(item);
    // }
    const uploadCatalog = await createCatalog(fileData);
    if (Object.keys(uploadCatalog).includes("_id")) {
      catalogs.unshift(uploadCatalog);
      setOpenNewCatalogForm(false);
    }
  };
  const handleUploadPortraidImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    const pdfIndex = filesArray.findIndex((file) => !file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      filesArray.unshift(file);
    }
    setFiles(filesArray);
  };
  const handleUploadCatalog = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray;
    const pdfIndex = files.findIndex((file) => file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      files.push(file);
      filesArray = files;
    }
    setFiles(filesArray);
  };
  //   useEffect(() => {
  //     console.log(files);
  //   }, [files]);

  return (
    <form onSubmit={SubmitForm} className="CatalogNewForm">
      <div className="CatalogNewForm__title">
        <h1>Nuevo catálogo</h1>
        <IoIosClose
          className="CatalogNewForm__title__close"
          onClick={() => setOpenNewCatalogForm(false)}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="year">Año</label>
        <input className="yearInput" type="text" name="year" id="year" />
        {formYearError && <p>*Por favor, rellene este campo</p>}
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="portraidImage">Imagen de portada</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadPortraidImage}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Catálogo</label>
        <input
          type="file"
          name="catalog"
          id="catalog"
          accept=".pdf"
          onChange={(e) => handleUploadCatalog(e)}
        />
      </div>
      <div className="CatalogNewForm__buttons">
        <button tpye="submit" className="subHeader__btn ">
          Crear
        </button>
        <button
          tpye="button"
          className="buttonFormCancel"
          onClick={() => setOpenNewCatalogForm(false)}
        >
          Cancelar
        </button>
        {formFilesError && (
          <p>
            *Por favor, asegúrese de que se han rellenado los campos de imagen y
            catálogo
          </p>
        )}
      </div>
    </form>
  );
};

export default CatalogNewForm;

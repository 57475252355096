import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { updateServicesTextAndImage } from "../../api/webData.api";
import "./ServicesEditForm.scss";

const ServicesEditForm = ({
  setOpenEditServicesForm,
  webData,
  setWebData,
  setHideContent,
  imageName,
  service,
}) => {
  const [files, setFiles] = useState([]);
  const [title, setTile] = useState("");
  const [description, setDescription] = useState("");
  const SubmitForm = async (e) => {
    e.preventDefault();
    const fileData = new FormData();
    fileData.append("title", e.target.ServiceTitle.value);
    fileData.append("description", e.target.ServiceDescription.value);

    for (let file of files) {
      fileData.append(`${imageName}`, file);
    }

    const uploadHomeServices = await updateServicesTextAndImage(
      fileData,
      imageName,
      webData._id
    );
    if (Object.keys(uploadHomeServices).includes("_id")) {
      if (imageName === "interiorimsImage") {
        webData.services.interiorims.title =
          uploadHomeServices.services.interiorims.title;
        webData.services.interiorims.image =
          uploadHomeServices.services.interiorims.image;
        webData.services.interiorims.description =
          uploadHomeServices.services.interiorims.description;
      } else if (imageName === "developmentImage") {
        webData.services.development.title =
          uploadHomeServices.services.development.title;
        webData.services.development.image =
          uploadHomeServices.services.development.image;
        webData.services.development.description =
          uploadHomeServices.services.development.description;
      } else if (imageName === "investMentImage") {
        webData.services.investment.title =
          uploadHomeServices.services.investmen?.title;
        webData.services.investment.image =
          uploadHomeServices.services.investmen?.image;
        webData.services.investment.description =
          uploadHomeServices.services.investment?.description;
      } else {
        webData.services.assetManagement.title =
          uploadHomeServices.services.assetManagement.title;
        webData.services.assetManagement.image =
          uploadHomeServices.services.assetManagement.image;
        webData.services.assetManagement.description =
          uploadHomeServices.services.assetManagement.description;
      }
      setOpenEditServicesForm(false);
      setHideContent(false);
    }
  };
  const handleUploadPortraidImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    const pdfIndex = filesArray.findIndex((file) => !file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      filesArray.unshift(file);
    }
    setFiles(filesArray);
  };

  useEffect(() => {
    if (imageName === "interiorimsImage") {
      setTile(webData.services.interiorims?.title);
      setDescription(webData.services.interiorims?.description);
    } else if (imageName === "developmentImage") {
      setTile(webData.services.development?.title);
      setDescription(webData.services.development?.description);
    } else if (imageName === "investMentImage") {
      setTile(webData.services.investment?.title);
      setDescription(webData.services.investment?.description);
    } else {
      setTile(webData.services.assetManagement?.title);
      setDescription(webData.services.assetManagement?.description);
    }
  }, []);

  return (
    <form onSubmit={SubmitForm} className="ServicesEditForm">
      <div className="ServicesEditForm__title">
        <h1>Editar Servicios</h1>
        <IoIosClose
          className="ServicesEditForm__title__close"
          onClick={() => {
            setOpenEditServicesForm(false);
            setHideContent(false);
          }}
        />
      </div>
      <div className="ServicesEditForm__item">
        <label htmlFor="catalog">Título</label>
        <input
          type="text"
          name="ServiceTitle"
          id="ServiceTitle"
          defaultValue={title}
        />
      </div>
      <div className="ServicesEditForm__item">
        <label htmlFor="catalog">Descripción</label>
        <textarea
          name="ServiceDescription"
          id="ServiceDescription"
          rows="4"
          cols="50"
          defaultValue={description}
        />
      </div>
      <div className="ServicesEditForm__item">
        <label htmlFor="portraidImage">Imagen de portada</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadPortraidImage}
        />
      </div>
      <div className="ServicesEditForm__buttons">
        <button
          tpye="button"
          className="subHeader__btn"
          onClick={() => {
            setOpenEditServicesForm(false);
            setHideContent(false);
          }}
        >
          Cancelar
        </button>
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
      </div>
    </form>
  );
};

export default ServicesEditForm;

import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import "./TitleAndImageForm.scss";
import { updateHomeServicesTextAndImage } from "../../../api/webData.api";

const SectionsEditForm = ({
  setOpenEditHomeServicesForm,
  webData,
  setWebData,
  setHideContent,
  imageName,
}) => {
  const [files, setFiles] = useState([]);
  const [title, setTile] = useState("");
  const [description, setDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const SubmitForm = async (e) => {
    e.preventDefault();
    const fileData = new FormData();
    fileData.append("title", e.target.ServiceTitle.value);
    fileData.append("buttonText", e.target.ServiceButtonText.value);
    fileData.append("description", e.target.ServiceDescription.value);

    // for (let item of fileData) {
    //   console.log(item);
    // }
    let serviceUrl;
    if (imageName === "Interiorismo") serviceUrl = "interiorism";
    else if (imageName === "Ventas") serviceUrl = "sell";
    else if (imageName === "Oficinas") serviceUrl = "offices";
    for (let file of files) {
      fileData.append(`${serviceUrl}Image`, file);
    }

    const uploadHomeServices = await updateHomeServicesTextAndImage(
      fileData,
      serviceUrl,
      webData._id
    );
    if (Object.keys(uploadHomeServices).includes("_id")) {
      if (imageName === "Interiorismo") {
        webData.sections.interiorims.title =
          uploadHomeServices.sections.interiorims.title;
        webData.sections.interiorims.image =
          uploadHomeServices.sections.interiorims.image;
        webData.sections.interiorims.description =
          uploadHomeServices.sections.interiorims.description;
        webData.sections.interiorims.buttonText =
          uploadHomeServices.sections.interiorims.buttonText;
      } else if (imageName === "Ventas") {
        console.log("en ventas");
        webData.sections.sell.title = uploadHomeServices.sections.sell.title;
        webData.sections.sell.image = uploadHomeServices.sections.sell.image;
        webData.sections.sell.description =
          uploadHomeServices.sections.sell.description;
        webData.sections.sell.buttonText =
          uploadHomeServices.sections.sell.buttonText;
      } else if (imageName === "Oficinas") {
        webData.sections.offices.title =
          uploadHomeServices.sections.offices.title;
        webData.sections.offices.image =
          uploadHomeServices.sections.offices.image;
        webData.sections.offices.description =
          uploadHomeServices.sections.offices.description;
        webData.sections.offices.buttonText =
          uploadHomeServices.sections.offices.buttonText;
      }
      setOpenEditHomeServicesForm(false);
      setHideContent(false);
    }
  };
  const handleUploadPortraidImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let filesArray = files;
    const pdfIndex = filesArray.findIndex((file) => !file.type.includes("pdf"));
    if (pdfIndex !== -1) {
      filesArray = files.toSpliced(pdfIndex, 1, file);
    } else {
      filesArray.unshift(file);
    }
    setFiles(filesArray);
  };

  useEffect(() => {
    if (imageName === "Interiorismo") {
      setTile(webData.sections.interiorims.title);
      setDescription(webData.sections.interiorims.description);
      setButtonText(webData.sections.interiorims.buttonText);
    } else if (imageName === "Ventas") {
      setTile(webData.sections.sell.title);
      setDescription(webData.sections.sell.description);
      setButtonText(webData.sections.sell.buttonText);
    } else if (imageName === "Oficinas") {
      setTile(webData.sections.offices.title);
      setDescription(webData.sections.offices.description);
      setButtonText(webData.sections.offices.buttonText);
    }
  }, []);

  return (
    <form onSubmit={SubmitForm} className="CatalogNewForm">
      <div className="CatalogNewForm__title">
        <h1>Editar Home</h1>
        <IoIosClose
          className="CatalogNewForm__title__close"
          onClick={() => {
            setOpenEditHomeServicesForm(false);
            setHideContent(false);
          }}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Título</label>
        <input
          type="text"
          name="ServiceTitle"
          id="ServiceTitle"
          defaultValue={title}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Descripción</label>
        <textarea
          name="ServiceDescription"
          id="ServiceDescription"
          rows="4"
          cols="50"
          defaultValue={description}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="portraidImage">Imagen de portada</label>
        <input
          type="file"
          name="portraidImage"
          id="portraidImage"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleUploadPortraidImage}
        />
      </div>
      <div className="CatalogNewForm__item">
        <label htmlFor="catalog">Botón</label>
        <input
          type="text"
          name="ServiceButtonText"
          id="ServiceButtonText"
          defaultValue={buttonText}
        />
      </div>
      <div className="CatalogNewForm__buttons">
        <button
          tpye="button"
          className="subHeader__btn"
          onClick={() => {
            setOpenEditHomeServicesForm(false);
            setHideContent(false);
          }}
        >
          Cancelar
        </button>
        <button tpye="submit" className="subHeader__btn ">
          Editar
        </button>
      </div>
    </form>
  );
};

export default SectionsEditForm;

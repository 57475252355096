import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../components/Context/AuthUser";
import ContactCard from "../../components/ContactCard/ContactCard";
import { getAllContacts } from "../../api/contacts.api";
import PopUp from "../../components/PopUp/PopUp";
import Layout from "../Layout/Layout";
import Spinner from "../../components/Spinner/Spinner";
import OldPagination from "../../components/Pagination/OldPagination";
import ContactValidation from "../../components/ContactValidation/ContactValidation";
import { MdOutlineSearchOff } from "react-icons/md";
import { checkSession } from "../../api/auth.api";

const ContactsList = () => {
  const [contacts, setContacts] = useState([]);
  const [contactsFiltered, setContactsFiltered] = useState([]);
  const [popUp, setPopUp] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [qPerPage] = useState(100);
  const [loader, setLoader] = useState(true);

  const { user, deleteUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handlePopUp = () => {
    setPopUp(!popUp);
  };

  // useEffect(() => {
  //   checkSession().then((res) => {
  //     if (res === "Acceso restringido") {
  //       deleteUser();
  //       navigate("/");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    getAllContacts().then((res) => {
      setContacts(res);
      setContactsFiltered(res);
      setLoader(false);
    });
  }, []);

  const indexOfLastContact = currentPage * qPerPage;
  const indexOfFirstContact = indexOfLastContact - qPerPage;
  let currentContacts = contactsFiltered?.slice(
    indexOfFirstContact,
    indexOfLastContact
  );
  let contactsLength = contactsFiltered?.length;

  const paginate = (n) => {
    setCurrentPage(n);
    window.scrollTo({ top: 0 });
  };

  const ContactListFooter = () => (
    <OldPagination
      qPerPage={qPerPage}
      totalQ={contactsLength}
      paginate={paginate}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );

  useEffect(() => {
    if (user.length === 0) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <Layout
      subTitle="Contactos"
      subList={contacts}
      subLocation={() => handlePopUp(contacts)}
      subSetter={setContactsFiltered}
      footContent={<ContactListFooter />}
    >
      {popUp && (
        <PopUp
          handlePopUp={handlePopUp}
          height="90%"
          mobileHeight="90%"
          width="45%"
          title="Crear contacto nuevo"
        >
          <ContactValidation list={contacts} />
        </PopUp>
      )}
      {loader ? (
        <Spinner />
      ) : currentContacts.length === 0 ? (
        <div style={{ height: 200 }}>
          <p style={{ lineHeight: 4 }}>No se ha encontrado ningún contacto</p>
          <MdOutlineSearchOff fontSize="2.5em" />
        </div>
      ) : (
        currentContacts.map((contact, index) => (
          <ContactCard key={contact._id} contact={contact} />
        ))
      )}
    </Layout>
  );
};

export default ContactsList;
